.member-points {
  width: 100%;
  height: 100%;
  background: #f5f5fa;
  // display: flex;
  // flex-direction: column;
  header {
    width: 100%;
    background: url(./img/header-bg.png) no-repeat center center;
    background-size: cover;
    padding: 35px 0;
    box-sizing: border-box;
    .head {
      width: 100%;
      height: 40px;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .points {
      text-align: center;
      font-size: 90px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-top: 69px;
      margin-bottom: 30px;
    }
  }
  .list {
    width: 100%;

    // flex: 1;
    // display: flex;
    // flex-direction: column;
    .nav {
      width: 100%;
      height: 120px;
      flex-shrink: 0;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      li {
        height: 70px;
        background: #ffffff;
        border: 2px solid #ff9664;
        border-radius: 35px;
        padding: 0 80px;
        line-height: 70px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff9664;
      }
      .active {
        background: #fa5564;
        border: none;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: relative;
      }
      .active:before {
        position: absolute;
        display: block;
        content: "";
        left: 50%;
        margin-left: -15px;
        background: url(./img/active.png) no-repeat center center;
        background-size: cover;
        width: 31px;
        height: 9px;
        bottom: -8px;
        z-index: 99;
      }
    }
    .points-box {
      width: 100%;
      background-color: #fff;
      padding: 0 30px;
      box-sizing: border-box;
      flex: 1;
      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 140px;
          border-bottom: 1px solid #f5f5fa;
          .points-left {
            height: 100%;
            display: flex;
            align-items: center;
            .icon-points {
              width: 36px;
              height: 36px;
              margin-right: 12px;
            }
            .title {
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #0f0f14;
            }
            .time {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #b9b9be;
              margin-top: 11px;
              font-style: normal;
            }
          }
          .income {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #fa5564;
          }
          .expend {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #b9b9be;
          }
        }
        .loading {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #b9b9be;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
