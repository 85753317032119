.SelectStoreaddress {
  width: 100%;
  min-height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 110px;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    top: 0;
    img {
      width: 40px;
      height: 40px;
    }
    .remove {
      width: 38px;
      height: 38px;
    }
    p {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
    }
  }
  .select {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    .cont {
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;

      border-radius: 30px;
      padding: 60px 30px;
      position: relative;
      h1 {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
      }
      //   > div {
      //     display: flex;
      //   }
      .position {
        display: flex;
        justify-content: space-between;
        margin-top: 19px;
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #737378;
        }
        .distance {
          display: flex;
          align-items: center;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #a18873;
          .location {
            width: 32px;
            height: 36px;
            margin-right: 10px;
          }
          .right {
            width: 14px;
            height: 26px;
            margin-left: 20px;
          }
        }
      }
      .time {
        display: flex;
        align-items: center;
        margin-top: 30px;
        span {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          flex-shrink: 0;
        }
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: light;
          color: #0f0f14;
        }
      }
      .flag {
        position: absolute;
        left: 30px;
        top: 0;
        height: 40px;
        background: #fa5564;
        border-radius: 0px 0px 14px 14px;
        padding: 0 17px;
        line-height: 40px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        font-style: normal;
        display: block;
      }
    }
    .active {
      border: 2px solid #fa5564;
      position: relative;
    }
  }
  .address-list {
    width: 100%;
    background-color: #fff;
    padding: 0 30px;
    box-sizing: border-box;
    flex: 1;
    padding-bottom: 144px;
    // div {
    //   width: 100%;
    //   border-bottom: solid 1px #d7d7dc;
    //   padding: 35px 0;
    // }
    .adm-space {
      width: 100%;
      .adm-space-item {
        border-bottom: solid 1px #d7d7dc;
        padding: 35px 0;
        .adm-radio {
          width: 100%;
          padding: 0 30px;
          box-sizing: border-box;
          .adm-radio-custom-icon {
            width: 26px;
            height: 18px;
            .adm-image {
              width: 26px;
              height: 18px;
              margin-right: 3px;
            }
          }
          .adm-radio-content {
            .address {
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0f0f14;
              line-height: 40px;
            }
            .info {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #737378;
              margin-top: 29px;
              .phone {
                margin-left: 50px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    border-top: solid 2px #f5f5fa;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 144px;
    padding: 22px 30px;
    box-sizing: border-box;
    background-color: #fff;
    button {
      width: 100%;
      height: 80px;
      background: #fa5564;
      border-radius: 40px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      border: none;
      outline: none;
    }
  }
}
