.index {
  font-size: 14px;
  color: #FFF;

  a {
    text-decoration: none;
    color: #FFF;
  }

  .warp {
    margin: 0 auto;

    .nav {
      padding: 0 40px;
      text-align: right;
      font-size: 24px;

      strong,
      a {
        display: inline-block;
        padding: 15px;
      }
    }

    img {
      width: 100%;
    }
  }

  .bottom {
    display: table;
    width: 100%;
    height: 160px;
    line-height: 40px;
    text-align: center;
    background: #A18873;

    .middle-inner {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      span {
        padding: 0 10px;
      }
    }
  }
}