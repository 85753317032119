.newShippingAddress {
  width: 100%;
  min-height: 100%;
  background: #f5f5fa;

  header {
    width: 100%;
    height: 110px;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    img {
      width: 40px;
      height: 40px;
    }
    p {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
    }
  }
  .form {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    // border-radius: 30px;

    .form-top {
      padding: 0 30px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 30px;
      > div {
        display: flex;
        height: 90px;
        align-items: center;
        border-bottom: solid 1px #f5f5fa;
        > span {
          width: 160px;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0f0f14;
          flex-shrink: 0;
        }
        > .adm-input {
          flex: 1;
          input {
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            // margin-left: 40px;
          }
        }
      }
      .phone {
        display: flex;
        flex: 1;
        span {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0f0f14;
          margin-right: 10px;
        }
        .adm-input {
          flex: 1;
          input {
            width: 100%;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            // margin-left: 40px;
          }
        }
      }
      .place {
        display: flex;
        flex: 1;
        align-items: center;
        span {
          flex: 1;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          display: block;
          .placeholder {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #9b9ba0;
          }
          em {
            font-style: normal;
          }
        }

        .adm-image {
          width: 14px;
          height: 26px;
          display: block;
        }
      }
      .detail-adress {
        height: 247px;
        display: flex;
        align-items: flex-start;
        padding-top: 25px;
        // line-height: 90px;
        span {
          height: 90px;
          //   line-height: 90px;
        }
        textarea {
          width: 100%;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
        }
      }
    }
    .form-bot {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 30px;
      box-sizing: border-box;

      //   height: 150px;
      background: #ffffff;
      border-radius: 30px;

      .titile {
        display: flex;
        flex-direction: column;
        h1 {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          margin-bottom: 10px;
        }
        p {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #737378;
        }
      }
      .adm-switch {
        scale: 1;
        margin-right: 30px;
        .adm-switch-checkbox:before {
          // width: calc(100% - 40px);
          // height: 100%;
          background: #f1f1f1;
        }
      }
      //   .adm-switch {
      //     width: 90px;
      //     height: 40px;
      //     .adm-switch-checkbox {
      //       width: 100%;
      //       height: 100%;
      //       .adm-switch-handle {
      //         width: 40px;
      //         height: 40px;
      //         border-radius: 100%;
      //       }
      //       //   .adm-switch-inner {
      //       //     width: 100%;
      //       //     height: 100%;
      //       //   }
      //     }
      //     // .adm-switch-checkbox:before {
      //     //   width: calc(100% - 40px);
      //     //   height: 100%;
      //     //   background: #f1f1f1;
      //     // }
      //   }
    }
  }
  .footer {
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 22px 30px;
    box-sizing: border-box;
    button {
      width: 100%;
      height: 80px;
      background: #d7d7dc;
      border-radius: 40px;
      margin: 0 auto;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      border: none;
      outline: none;
    }
    .active {
      background: #fa5564;
    }
  }
}
.adm-popup-body {
  padding: 30px 30px 0;
  box-sizing: border-box;
  .regions-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .head {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
    .cont {
      width: 100%;
      flex: 1;
      margin-top: 70px;
      overflow-y: hidden;

      > .country {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > .adm-tabs-header {
          width: 50%;
          .adm-tabs-tab {
            margin-left: 0;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            padding-bottom: 12px;
            box-sizing: border-box;
          }
          .adm-tabs-tab-line {
            height: 4px;
            background: #ffd345;
            border-radius: 2px;
          }
        }
        .defaultBox {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: hidden;
          flex: 1;
        }
        > .adm-tabs-content {
          padding-top: 47px;
          box-sizing: border-box;
          flex: 1;
          overflow: hidden;
          box-sizing: border-box;
          .hot-box {
            .hot {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #9b9ba0;
              margin-bottom: 43px;
              box-sizing: border-box;
            }
            .hot-city {
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              align-content: center;
              padding-right: 60px;
              li {
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                margin-bottom: 46px;
                // padding: 0 30px;
                box-sizing: border-box;
              }
            }
          }

          .selectedCity {
            width: 100%;
            display: flex;
            height: 50px;
            margin-bottom: 30px;
            // height: 100%;
            .adm-tabs-header {
              border-bottom: none;
            }

            .adm-tabs-tab-list {
              .adm-tabs-tab-line {
                display: none;
              }
              .adm-tabs-tab {
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                margin-right: 50px;
              }
              .adm-tabs-tab-active {
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #fa5564;
              }
            }
          }

          // .checked {
          //   width: 100%;
          //   display: flex;
          //   span {
          //     font-size: 26px;
          //     font-family: PingFang SC;
          //     font-weight: 400;
          //     color: #0f0f14;
          //     margin-right: 50px;
          //   }
          //   .active {
          //     font-size: 26px;
          //     font-family: PingFang SC;
          //     font-weight: 500;
          //     color: #fa5564;
          //   }
          // }
          > .adm-swiper {
            // height: calc(100% - 500px);
            flex: 1;
            padding-bottom: 20px;
            box-sizing: border-box;
          }

          .province {
            // flex: 1;
            width: 100%;
            height: 100%;
            padding-top: 40px;
            position: relative;
            overflow-y: scroll;
            box-sizing: border-box;
            > ul {
              h1 {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              li {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              .provinceActive {
                color: #fa5564;
              }
              li:not(:last-child) {
                border-bottom: solid 1px #f5f5fa;
              }
            }

            .letter {
              width: 30px;
              position: fixed;
              right: 0;
              top: 0;
              background: rgba(215, 215, 220, 0.3);
              border-radius: 15px;
              max-height: 100%;
              overflow-y: scroll;
              div {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                // overflow-y: scroll;
                a {
                  width: 100%;
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #9b9ba0;
                  // margin: 10px 0;
                  text-align: center;
                  line-height: 45px;
                  text-decoration: none;
                }
                .provinceActive {
                  color: #fa5564;
                }
              }
            }
          }
          .citys {
            // flex: 1;
            width: 100%;
            height: 100%;
            padding-top: 40px;
            position: relative !important;
            overflow-y: scroll;
            box-sizing: border-box;
            > ul {
              h1 {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              li {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              .provinceActive {
                color: #fa5564;
              }
              li:not(:last-child) {
                border-bottom: solid 1px #f5f5fa;
              }
            }

            .letter {
              width: 30px;
              position: fixed;
              right: -100%;
              top: 0;
              background: rgba(215, 215, 220, 0.3);
              border-radius: 15px;
              overflow-y: scroll;
              div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                a {
                  width: 100%;
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #9b9ba0;
                  // margin: 10px 0;
                  text-align: center;
                  line-height: 45px;
                  text-decoration: none;
                }
                .provinceActive {
                  color: #fa5564;
                }
              }
            }
          }
          .counties {
            // flex: 1;
            width: 100%;
            height: 100%;
            padding-top: 40px;
            position: relative !important;
            overflow-y: scroll;
            box-sizing: border-box;
            > ul {
              h1 {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              li {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                height: 70px;
                line-height: 70px;
              }
              .provinceActive {
                color: #fa5564;
              }
              li:not(:last-child) {
                border-bottom: solid 1px #f5f5fa;
              }
            }

            .letter {
              width: 30px;
              position: fixed;
              right: -200%;
              top: 0;
              background: rgba(215, 215, 220, 0.3);
              border-radius: 15px;
              overflow-y: scroll;
              div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                a {
                  width: 100%;
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #9b9ba0;
                  // margin: 10px 0;
                  text-align: center;
                  line-height: 45px;
                  text-decoration: none;
                }
                .provinceActive {
                  color: #fa5564;
                }
              }
            }
          }
        }
      }
    }
  }
}
