.store-detail {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);
  .header {
    width: 100%;
    height: 560px;
    .adm-swiper {
      width: 100%;
      height: 100%;
      .adm-swiper-slide {
        width: 100%;
        height: 100%;
        .swiper-content {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
      .adm-swiper-indicator {
        bottom: 20px;
        .adm-page-indicator-dot {
          width: 20px;
          height: 20px;
          border: 2px solid #f5f5f5;
          border-radius: 50%;
          background: transparent;
          margin-right: 12px;
        }
        .adm-page-indicator-dot-active {
          background: #fff;
        }
      }
    }

    .search {
      position: fixed;
      width: 100%;
      padding: 20px 30px 20px 40px;
      height: 110px;
      top: 40px;
      z-index: 999;
      display: flex;
      align-items: center;

      .back {
        width: 70px;
        height: 70px;
        display: block;
        margin-right: 25px;
      }
      .search-box {
        // width: 605px;
        flex: 1;
        height: 70px;
        background: #f5f5fa;
        opacity: 0.95;
        border-radius: 35px;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        padding-right: 25px;
        box-sizing: border-box;
        p {
          height: 100%;
          line-height: 70px;
          // border: none;
          // height: 100%;
          padding: 0 10px;
          font-size: 26px;
          // font-family: PingFang SC;
          font-weight: 300;
          color: #9b9ba0;
          // outline: none;
          flex: 1;
          // width: 546px;
          // box-sizing: border-box;
          // background-color: transparent;
        }
        .searchButton {
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
      }
    }
    .searchActive {
      background-color: #fff;
      top: 0;
      .back {
        width: 40px !important;
        height: 44px !important;
        display: block !important;
        margin-right: 53px !important;
      }
    }
  }
  .inform {
    width: 100%;
    height: 80px;
    // background: #fff;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px #f5f5fa;

    .trumpet {
      width: 36px;
      height: 40px;
      margin-right: 20px;
    }
    .notice {
      flex: 1;
      margin-right: 28px;
      height: 80px;
      overflow: hidden;
      .adm-swiper-indicator {
        display: none;
      }
      .adm-swiper-item {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 80px;
        // display: block;
        display: flex;
        align-items: center;
        p {
          flex: 1;
        }
        .informRight {
          width: 12px;
          height: 24px;
          display: block;
        }
      }
    }
    // p {
    //   // width: 593px;
    //   flex: 1;
    //   font-size: 26px;
    //   font-family: PingFang SC;
    //   font-weight: 300;
    //   color: #0f0f14;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    //   overflow: hidden;
    //   margin-right: 28px;
    //   display: block;
    // }
  }
  .store-content {
    width: 100%;
    background: #f5f5fa;
    .shop-about {
      width: 100%;
      background: #fff;
      padding: 0 30px 70px;
      box-sizing: border-box;
      .shop-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .title-info {
          p {
            font-size: 34px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #0f0f14;
            margin: 49px 0 30px 0;
          }
          > .evaluate {
            display: flex;
            align-items: center;
            background-color: #fff;
            .stars {
              img {
                width: 36px;
                height: 34px;
                margin-right: 6px;
              }
            }
            span {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #a18774;
              margin-left: 21px;
            }
          }
          .shop-functions {
            margin-top: 20px;
            display: flex;
            span {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #ffffff;
              border-radius: 10px;
              padding: 0 14px;
              height: 40px;
              line-height: 40px;
              margin-right: 16px;
            }
          }
        }
        .collect {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 44px;
          img {
            width: 46px;
            height: 44px;
          }
          span {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #9ba0af;
            margin-top: 11px;
          }
        }
      }
      .shop-bottom {
        width: 100%;
        margin-top: 48px;

        > div {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
          justify-content: space-between;
          .label {
            display: flex;
            align-items: center;
            margin-right: 28px;
            flex-shrink: 0;
            img {
              width: 36px;
              height: 36px;
            }
            span {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #737378;
              margin-left: 10px;
            }
          }
          .tel {
            flex: 1;
            display: flex;
            text-decoration: none;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            p {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0f0f14;
              line-height: 40px;
              flex: 1;
              width: 150px;
              // a {
              //   text-decoration: none;
              //   font-size: 28px;
              //   font-family: PingFang SC;
              //   font-weight: 400;
              //   color: #0f0f14;
              //   line-height: 40px;
              // }
            }
            .arrow-right {
              margin-left: 44px;
              width: 14px;
              height: 26px;
              align-self: center;
            }
          }
          p {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            flex: 1;
            width: 150px;
            a {
              text-decoration: none;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0f0f14;
              line-height: 40px;
            }
          }
          .hiddenText {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            -webkit-line-clamp: 4;
          }
          .shop-brief-arrow {
            display: flex;
            align-self: flex-end;
            align-items: center;
            span {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #d7d7dc;
              line-height: 40px;
              // margin-right: 29px;
            }
            img {
              width: 26px;
              height: 14px;

              margin-left: 30px;
            }
            // .arrow-down {
            //   width: 26px;
            //   height: 14px;

            //   margin-left: 30px;
            // }
          }
          .arrow-right {
            margin-left: 44px;
            width: 14px;
            height: 26px;
            align-self: center;
          }
        }
        .shop-brief {
          .brief-cont {
            display: flex;
            flex: 1;
            align-items: flex-start;
          }
        }
        .showAll {
          display: flex;
          flex-direction: column;
          .brief-cont {
            display: flex;
            width: 100%;
            flex: 1;
            padding-right: 50px;
            box-sizing: border-box;
          }
          .shop-brief-arrow {
            margin-top: 14px;
          }
        }
      }
    }
    .book-club {
      margin-top: 10px;
      background-color: #fff;
      padding: 25px 30px 70px;
      box-sizing: border-box;
      .title {
        width: 100%;
        background: url(./img/douhao.png) no-repeat left center;
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
        height: 80px;
        padding-left: 30px;
        padding-top: 10px;
        box-sizing: border-box;
        background-size: 62px 80px;
      }
      .book-swiper {
        width: 100%;
        height: 340px;
        border-radius: 10px;
        margin-top: 15px;
        overflow: hidden;
        .adm-swiper {
          width: 100%;
          height: 100%;
          .adm-swiper-slide {
            width: 100%;
            height: 100%;
            .swiper-content {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .adm-swiper-indicator {
            bottom: 20px;
            .adm-page-indicator-dot {
              width: 20px;
              height: 20px;
              border: 2px solid #f5f5f5;
              border-radius: 50%;
              background: transparent;
              margin-right: 12px;
            }
            .adm-page-indicator-dot-active {
              background: #fff;
            }
          }
        }
      }
    }
    .comment-box {
      margin-top: 10px;
      background-color: #fff;
      padding: 25px 30px 70px;
      box-sizing: border-box;
      .title {
        width: 100%;
        background: url(./img/douhao.png) no-repeat left center;
        height: 80px;
        padding-left: 30px;
        background-size: 62px 80px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-left {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0f0f14;
          em {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            font-style: normal;
            margin-left: 19px;
          }
        }
        .content-detail {
          display: flex;
          align-items: center;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #737378;
          font-style: normal;
          img {
            width: 14px;
            height: 26px;
            margin-left: 17px;
          }
        }
      }
      .comments-content {
        width: 100%;
        padding: 0 30px;
        margin-top: 35px;
        > div {
          margin-bottom: 50px;
          .head {
            width: 100%;
            display: flex;
            align-items: center;
            .head-photo {
              width: 54px;
              height: 54px;
              border-radius: 100%;
              display: block;
              margin-right: 16px;
            }
            .nickname {
              padding: 1px 0;
              box-sizing: border-box;
              span {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #737378;
              }
              .stars {
                margin-top: 7px;
                img {
                  width: 21px;
                  height: 20px;
                  margin-right: 4px;
                }
              }
            }
          }
          .content {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            margin-top: 26px;
          }
          .images {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 30px;
            .head-photo {
              width: 300px;
              height: 300px;
              border-radius: 10px;
              display: block;
            }
            div {
              width: 300px;
              height: 300px;
              border-radius: 10px;

              // background-color: #737378;
            }
          }
          .many {
            width: 100%;
            display: flex;

            flex-wrap: wrap;
            margin-top: 30px;
            .head-photo {
              width: 200px;
              height: 200px;
              border-radius: 10px;
              display: block;
              margin-bottom: 20px;
              // margin-bottom: ;
            }
            .head-photo:not(:nth-child(3n)) {
              margin-right: 15px;
            }
            div {
              width: 200px;
              height: 200px;
              border-radius: 10px;
            }
          }

          p {
            margin-top: 19px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #64646f;
          }
        }
        .more {
          width: 100%;
          margin-top: 70px;
          span {
            width: 270px;
            height: 80px;
            border: 2px solid #d7d7dc;
            border-radius: 40px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #737378;
            cursor: pointer;
            // display: block;
            // text-align: center;
            // line-height: 80px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 14px;
              height: 26px;
              display: block;
              margin-left: 20px;
            }
          }
        }
      }
    }
    .products-box {
      margin-top: 10px;
      background-color: #fff;
      padding: 25px 30px 70px;
      box-sizing: border-box;
      .title {
        width: 100%;
        background: url(./img/douhao.png) no-repeat left center;
        height: 80px;
        padding-left: 30px;
        background-size: 62px 80px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-left {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0f0f14;
          em {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            font-style: normal;
            margin-left: 19px;
          }
        }
        .content-detail {
          display: flex;
          align-items: center;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #737378;
          font-style: normal;
          img {
            width: 14px;
            height: 26px;
            margin-left: 17px;
          }
        }
      }
      .products {
        padding: 15px 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .pro-item {
          width: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 60px;
          .book_img {
            width: 300px;
            height: 300px;
            border-radius: 6px;
          }
          .des {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            margin-top: 30px;
            height: 80px;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            display: -webkit-box;

            -webkit-box-orient: vertical;

            overflow: hidden;
            -webkit-line-clamp: 2;
          }
          .price {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 29px;
            align-items: flex-end;
            span {
              font-size: 40px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #fa5564;
              line-height: 40px;
              display: flex;
              align-items: flex-end;
              em {
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 600;
                color: #fa5564;
                line-height: 40px;
                margin-right: 8px;
                font-style: normal;
              }
            }
            b {
              font-weight: normal;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 300;
              text-decoration: line-through;
              color: #9b9ba0;
            }
          }
          button {
            width: 300px;
            height: 70px;
            background: #fa5564;
            border-radius: 35px;
            border: none;
            outline: none;
            line-height: 70px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff0aa;
            line-height: 40px;
            margin-top: 20px;
          }
        }
        .more {
          width: 100%;
          margin-top: 10px;
          span {
            width: 270px;
            height: 80px;
            border: 2px solid #d7d7dc;
            border-radius: 40px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #737378;
            cursor: pointer;
            // display: block;
            // text-align: center;
            // line-height: 80px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 14px;
              height: 26px;
              display: block;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
// .adm-swiper {
//   width: 100%;
//   height: 100%;
//   .adm-swiper-slide {
//     width: 100%;
//     height: 100%;
//     .swiper-content {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .adm-swiper-indicator {
//     bottom: 20px;
//     .adm-page-indicator-dot {
//       width: 20px;
//       height: 20px;
//       border: 2px solid #f5f5f5;
//       border-radius: 50%;
//       background: transparent;
//       margin-right: 12px;
//     }
//     .adm-page-indicator-dot-active {
//       background: #fff;
//     }
//   }
// }
