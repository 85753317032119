.incomeAndRank {
  width: 100%;
  height: 100%;
  background: #f5f5fa;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 150px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    padding: 40px 30px 20px 30px;
    box-sizing: border-box;
    background-color: #fff;
    justify-content: space-between;
    z-index: 999;

    span {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
      margin-left: 50px;
    }
    .back {
      width: 40px;
      height: 40px;
    }
    .change {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #9b9ba0;
      display: flex;
      align-items: center;
      .adm-image {
        width: 40;
        margin-left: 10px;
        padding: 10px 5px;
        box-sizing: border-box;
        .adm-image-img {
          width: 31px;
          height: 40px;
        }
        // padding: 10px;
      }
    }
    // .changeStore {
    //   padding: 10px;
    //   box-sizing: border-box;
    //   width: 31px;
    //   height: 40px;
    // }
  }
  .income {
    padding-top: 80px;
    // height: calc(100% - 260px);
    height: 100%;
    overflow-y: scroll;
    .date {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      background: #ac9685;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      position: fixed;
      top: 110px;
      z-index: 99;
      .adm-image {
        width: 18px;
        height: 10px;
        margin-left: 20px;
      }
    }
    .cont1 {
      margin-top: 50px;
      // padding-top: 80px;
      box-sizing: border-box;
      > .title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 20px;
        span {
          width: 12px;
          height: 30px;
          background: #64814d;
          margin-right: 19px;
          display: block;
        }
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
      }
      .retail-table {
        // width: 100%;
        background-color: #fff;
        padding: 40px 30px;
        box-sizing: border-box;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        .table {
          width: 100%;
          background: #fafafc;
          border: 2px solid #737378;
          // border-radius: 10px 0px 0px 10px;
          // border-right: 2px solid #737378;
          display: flex;
          overflow: hidden;
          overflow-x: scroll;
          // border-left: 2px solid #737378;

          padding-left: 240px;
          .header {
            overflow: hidden;
            // width: 202px;
            width: 240px;
            border-left: none;
            position: absolute;
            left: 32px;
            top: 42px;
            background: #fafafc;
            z-index: 88;
            border-right: 2px solid #737378;
          }
          ul:nth-child(2) {
            border-left: none;
          }
          ul {
            min-width: 240px;
            flex-shrink: 0;
            flex: 1;
            overflow: hidden;
            li {
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              height: 52px;
              padding-left: 17px;
              line-height: 52px;
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #0f0f14;
              box-sizing: border-box;
              // border-right: solid 2px #737378;
            }

            li:not(:first-child) {
              // border-bottom: 2px solid #737378;
              border-top: 2px solid #737378;
            }

            // border-bottom: 2px solid #737378;
            border-left: 2px solid #737378;
          }
        }
      }
      .retail-table-lirun {
        .table {
          padding-left: 360px !important;
        }
        .header {
          width: 360px !important;
        }
      }
    }
  }
  .rank {
    width: 100%;
    padding-top: 110px;

    // padding-top: 80px;
    // // height: calc(100% - 260px);
    height: 100%;
    // overflow-y: scroll;
    .classify {
      height: 100%;
      margin-top: 1px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-top: 80px;
      overflow-y:scroll;

      > .adm-tabs-header {
        width: 100%;
        height: 110px;
        background-color: #fff;
        position: fixed;
        top: 110px;
        .adm-tabs-tab-list {
          height: 110px;
          .adm-tabs-tab-wrapper {
            //   display: flex;
            //   justify-content: center;

            .adm-tabs-tab {
              height: 100%;
              // margin-left: 0;
              color: #737378;
              box-sizing: border-box;

              font-size: 34px;
              font-family: PingFang SC;
              font-weight: 400;
            }
            .adm-tabs-tab-active {
              color: #64814d;
            }
          }
        }
        .adm-tabs-tab-line {
          height: 4px;
          background: #ffd345;
          border-radius: 2px;
          width: 90px;
        }
      }
      .adm-tabs-content {
        padding: 0;
      }
      .date {
        width: 100%;
        position: fixed;
        top: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ac9685;
        background-color: #fff;

        .adm-image {
          width: 18px;
          height: 10px;
          margin-left: 20px;
        }
      }
      .cont1 {
        margin-top: 50px;
        overflow-y:scroll;
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 30px;
          box-sizing: border-box;
          margin-bottom: 20px;
          span {
            width: 12px;
            height: 30px;
            background: #64814d;
            margin-right: 19px;
            display: block;
          }
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
        }
        .retail-table {
          width: 100%;
          background-color: #fff;
          padding: 40px 30px;
          box-sizing: border-box;
          margin-bottom: 30px;

          .table {
            width: 100% !important;
            background: #fafafc;
            border: 2px solid #737378;
            border-radius: 10px 0px 0px 10px;
            // border-right: 2px solid #737378;
            display: flex;
            overflow: hidden;
            overflow-x: scroll;
            // flex: 1 !important;
            // border-left: 2px solid #737378;
            .header {
              // width: 202px;
              border-left: none;
            }
            .rank-header {
              width: 82px !important;
            }
            > ul {
              // min-width: 150px;
              flex-shrink: 0;
              flex: 1;
              li {
                // width: 100%;
                display: block;
                box-sizing: border-box;
                height: 52px;
                padding: 0 17px;
                line-height: 52px;
                // min-width: 300px;
                white-space: nowrap;
                overflow: hidden; //文本超出隐藏
                text-overflow: ellipsis; //文本超出省略号替代
                // border-right: solid 2px #737378;
              }

              li:not(:first-child) {
                // border-bottom: 2px solid #737378;
                border-top: 2px solid #737378;
              }

              // border-bottom: 2px solid #737378;
              border-left: 2px solid #737378;
            }

            .books {
              li:not(:first-child) {
                color: #64814d;
              }
            }
          }
        }
      }
    }
  }
  .adm-mask {
    // position: relative;
    // z-index: 99;
    .income-dialog {
      width: 100%;

      position: absolute;
      background-color: #fff;
      bottom: 0;
      padding: 40px 30px;
      box-sizing: border-box;
      border-radius: 50px 50px 0px 0px;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
        }
        .adm-image {
          padding: 20px;
          box-sizing: border-box;
          .adm-image-img {
            width: 25px;
            height: 25px;
          }
        }
      }
      .choose {
        width: 100%;
        margin-top: 50px;
        .days {
          width: 100%;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          li {
            border: 2px solid #f5f5fa;
            border-radius: 35px;
            width: 162px;
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            flex-shrink: 0;
            margin-bottom: 30px;
          }
          // li:not(:2n-1) {
          //   margin-right: 14px;
          // }
          li:not(:nth-child(4n)) {
            margin-right: 14px;
          }
          .active {
            border: 2px solid #64814d;
            border-radius: 35px;
            color: #0f0f14;
          }
        }

        .values {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-top: 30px;
          margin-bottom: 20px;
          span {
            width: 300px;
            height: 70px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            border: 2px solid #f5f5fa;
            border-radius: 35px;
            line-height: 70px;
            text-align: center;
          }
          em {
            width: 30px;
            height: 4px;
            font-style: normal;
            background: #d7d7dc;
            border-radius: 2px;
            display: block;
          }
          .active {
            border: 2px solid #64814d;
            border-radius: 35px;
            color: #0f0f14;
          }
        }
        .btn {
          width: 100%;
          height: 80px;
          background: #64814d;
          border-radius: 40px;
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 80px;
          text-align: center;
          margin-top: 80px;
        }
      }
    }
  }
  .adm-picker-view {
    .adm-picker-view-column {
      .adm-picker-view-column-item {
        // height: 70px;
        .adm-picker-view-column-item-label {
          font-size: 36px;
          // font-family: PingFang SC;
          // font-weight: 400;
          color: #000000;
          // line-height: 64px;
        }
      }
    }
  }
  .changeStoreBox {
    width: 100%;
    background: #ffffff;
    border-radius: 50px 50px 0px 0px;
    padding: 40px 30px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        margin-left: 20px;
      }
      .adm-image {
        padding: 20px;
        box-sizing: border-box;
        .adm-image-img {
          width: 25px;
          height: 25px;
        }
      }
    }
    p {
      font-size: 28px !important;
      font-family: PingFang SC;
      font-weight: 300;
      color: #737378;
      margin-top: 29px;
      padding-left: 30px;
      box-sizing: border-box;
      margin-bottom: 80px;
    }
    .btn {
      width: 100%;
      height: 80px;
      background: #64814d;
      border-radius: 40px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 80px;
      text-align: center;
      margin-top: 80px;
    }
  }
  .footer {
    width: 100%;
    position: fixed;

    // position: absolute;
    bottom: 0;
    height: 150px;
    background-color: #fff;
    ul {
      width: 100%;
      height: 100%;

      padding: 20px 119px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      li {
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          margin-left: 20px;
        }
      }
      .active {
        span {
          color: #ac9685;
        }
      }
      li:first-child {
        .adm-image {
          width: 52px;
          height: 52px;
        }
      }
      li:last-child {
        .adm-image {
          width: 56px;
          height: 46px;
        }
      }
    }
  }
}
