.smsCode-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(101, 101, 101, 0.2);
    }

    .smsCode-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80%;
        border-radius: 0.53rem 0.53rem 0 0;
        background: #FFF;

        .smsCode-title {
            position: relative;
            line-height: 1.4rem;
            text-align: center;
            font-size: 0.4rem;
            border-bottom: 2px solid #F5F5FA;

            .smsCode-close {
                position: absolute;
                top: 0.45rem;
                right: 0.6rem;
                display: inline-block;
                width: 0.4rem;
                height: 0.4rem;
                background: url("./sms-close.png") 0 0 no-repeat;
                background-size: contain;
            }
        }

        ul {
            overflow-y: auto;

            li {
                display: flex;
                justify-content: space-between;
                padding: 0.25rem 0;
                margin: 0 0.6rem;
                border-bottom: 2px solid #F5F5FA;
                font-size: 0.4rem;
                color: #737378;

                strong {
                    color: #0F0F14;
                    font-weight: normal;
                }
            }
        }
    }
}