.order {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f5f5fa;
  display: flex;
  flex-direction: column;
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);
  .header {
    width: 100%;
    // height: 560px;
    .head {
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      box-sizing: border-box;
      background-color: #fff;
      img {
        width: 40px;
        height: 40px;
        display: block;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
      }
    }
    .adm-swiper {
      width: 100%;
      height: 290px;
      background-color: #fff;
      .adm-swiper-slide {
        width: 100%;
        height: 100%;
        .swiper-content {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
      .adm-swiper-indicator {
        bottom: 20px;
        .adm-page-indicator-dot {
          width: 20px;
          height: 20px;
          border: 2px solid #f5f5f5;
          border-radius: 50%;
          background: transparent;
          margin-right: 12px;
        }
        .adm-page-indicator-dot-active {
          background: #fff;
        }
      }
    }
  }
  .content {
    flex: 1;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .openTime {
      margin: 20px 0;
      height: 120px;
      background: #ffffff;
      border-radius: 60px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      .adm-image {
        width: 100px;
        height: 70px;
      }
      .timeInfo {
        padding: 26px;
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          line-height: 40px;
          span {
            color: #a18774;
          }
        }
      }
    }
    .menu {
      flex: 1;
      display: flex;
      overflow: hidden;
      .left {
        width: 150px;
        padding-top: 39px;
        margin-right: 11px;
        flex-shrink: 0;
        // flex: 1;
        ul {
          overflow: hidden;
          li {
            margin-bottom: 59px;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #0f0f14;
          }
          .active {
            font-weight: 400;
            color: #8c715e;
          }
        }
      }
      .right {
        flex: 1;
        // flex-shrink: 0;
        height: 100%;
        background: #ffffff;
        box-shadow: 0px 20px 30px 0px #ebeef5;
        border-radius: 40px 40px 0 0;
        height: 100%;
        padding: 40px 20px 186px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        > h1 {
          width: 100%;
          height: 40px;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          margin-bottom: 10px;
        }
        .list {
          width: 100%;
          flex: 1;
          overflow-y: scroll;
          .list-item {
            width: 100%;
            display: flex;
            margin: 60px 0;
            .adm-image {
              width: 200px;
              height: 200px;
              border-radius: 10px;
              margin-right: 24px;
            }
            .shop-info {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .title {
                font-size: 28px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0f0f14;
                line-height: 40px;
              }
              .salesVolume {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #9b9ba0;
                line-height: 40px;
                margin-top: 10px;
              }
              .count {
                width: 100%;
                padding-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;
                .price {
                  .now-price {
                    color: #fa5564;
                    font-size: 36px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #fa5564;
                    line-height: 40px;
                    em {
                      font-size: 26px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      line-height: 40px;
                      font-style: normal;
                      margin-right: 8px;
                    }
                  }
                  .original {
                    font-size: 26px;
                    font-family: PingFang SC;
                    font-weight: 300;
                    text-decoration: line-through;
                    color: #9b9ba0;
                    line-height: 40px;
                    margin-left: 10px;
                  }
                }
                .addBtn {
                  position: relative;
                  .adm-image {
                    width: 50px;
                    height: 50px;
                  }
                  .num {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    z-index: 999;
                    right: 10px;
                    top: -20px;
                    background: #ac9685;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    font-size: 28px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 36px;
                    text-align: center;
                  }
                }
              }
            }
          }
          .loading {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .cart {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    // height: 168px;
    padding: 11px 20px 20px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 2px #f5f5fa;
    z-index: 999;
    .cart-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cart-count {
        position: relative;
        .adm-image {
          width: 60px;
          height: 50px;
        }
        .num {
          width: 40px;
          height: 40px;
          background: #fa5564;
          border-radius: 50%;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          position: absolute;
          right: -20px;
          top: -10px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        .price {
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #fa5564;
          line-height: 40px;
          em {
            font-style: normal;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #fa5564;
            line-height: 40px;
            margin-right: 10px;
          }
        }
        .price-des {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #9b9ba0;
          margin-top: 12px;
          span {
            color: #fa5564;
          }
        }
      }
    }
    .choose {
      width: 196px;
      height: 90px;
      background: url(./img/ok.png) no-repeat center center;
      background-size: 100% 100%;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 80px;
    }
  }
}
