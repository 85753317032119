.mine-collect {
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  header {
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    display: flex;
    left: 0;
    align-items: center;
    background-color: #fff;
    z-index: 999;
    .head {
      width: 100%;
      // height: 40px;
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;

        color: #0f0f14;
      }
    }
  }
  .list {
    width: 100%;
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    padding: 0 30px;
    box-sizing: border-box;
    .collect-box {
      width: 100%;
      ul {
        // li {
        //   width: 100%;
        //   display: flex;
        //   align-items: center;
        //   height: 260px;
        //   margin-top: 60px;
        //   .img {
        //     width: 260px;
        //     height: 260px;
        //     position: relative;
        //     margin-right: 30px;
        //     flex-shrink: 0;
        //     .mask {
        //       width: 100%;
        //       height: 100%;
        //       background: #96b4e1;
        //       opacity: 0.5;
        //       border-radius: 10px;
        //       position: absolute;
        //       left: 0;
        //       top: 0;
        //       z-index: 2;
        //       span {
        //         background: #000000;

        //         height: 50px;
        //         display: block;
        //         bottom: 0;
        //         left: 0;
        //         border-radius: 0px 0px 10px 10px;
        //         position: absolute;
        //         width: 100%;
        //         font-size: 26px;
        //         font-family: PingFang SC;
        //         font-weight: 300;
        //         color: #ffffff;
        //         line-height: 50px;
        //         text-align: center;
        //       }
        //     }
        //     .adm-image {
        //       width: 100%;
        //       height: 100%;
        //       .adm-image-img {
        //         display: block;
        //         height: 100%;
        //         border-radius: 4px 6px 6px 4px;
        //       }
        //     }
        //   }
        //   .cont {
        //     width: calc(100% - 290px);
        //     padding: 10px 0;
        //     box-sizing: border-box;
        //     .name {
        //       font-size: 32px;
        //       font-family: PingFang SC;
        //       font-weight: 400;
        //       color: #0f0f14;
        //       text-overflow: ellipsis;
        //       overflow: hidden;
        //       white-space: nowrap;
        //       margin-bottom: 10px;
        //     }
        //     .price {
        //       display: flex;
        //       align-items: center;
        //       .now {
        //         font-size: 40px;
        //         font-family: PingFang SC;
        //         font-weight: 600;
        //         color: #fa5564;
        //         line-height: 40px;
        //         margin-right: 19px;
        //         em {
        //           font-size: 30px;
        //           font-family: PingFang SC;
        //           font-weight: 600;
        //           color: #fa5564;
        //           line-height: 40px;
        //           margin-right: 9px;
        //           font-style: normal;
        //         }
        //       }
        //       .original {
        //         font-size: 28px;
        //         font-family: PingFang SC;
        //         font-weight: 300;
        //         color: #737378;
        //       }
        //     }
        //     .des {
        //       width: 100%;
        //       font-size: 26px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #b9b9be;
        //       line-height: 34px;
        //       margin-top: 10px;
        //       p {
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         white-space: nowrap;
        //       }
        //     }
        //     .comment {
        //       font-size: 26px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #737378;
        //       line-height: 34px;
        //       margin-top: 15px;
        //       span {
        //         margin-right: 20px;
        //       }
        //     }
        //   }
        // }
        .loading {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #b9b9be;
          display: flex;
          justify-content: center;
          margin: 30px 0;
        }
      }
    }
    // .nav {
    //   width: 100%;
    //   height: 120px;
    //   padding: 0 30px;
    //   box-sizing: border-box;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   margin-top: 20px;
    //   li {
    //     height: 70px;
    //     background: #ffffff;
    //     border: 2px solid #ff9664;
    //     border-radius: 35px;
    //     padding: 0 80px;
    //     line-height: 70px;
    //     font-size: 30px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #ff9664;
    //   }
    //   .active {
    //     background: #fa5564;
    //     border: none;
    //     font-size: 30px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #ffffff;
    //     position: relative;
    //   }
    //   .active:before {
    //     position: absolute;
    //     display: block;
    //     content: "";
    //     left: 50%;
    //     margin-left: -15px;
    //     background: url(./img/active.png) no-repeat center center;
    //     width: 31px;
    //     height: 8px;
    //     bottom: -8px;
    //   }
    // }
    // .points-box {
    //   width: 100%;
    //   background-color: #fff;
    //   padding: 0 30px;
    //   box-sizing: border-box;
    //   flex: 1;
    //   ul {
    //     li {
    //       display: flex;
    //       justify-content: space-between;
    //       align-items: center;
    //       height: 140px;
    //       border-bottom: 1px solid #f5f5fa;
    //       .points-left {
    //         height: 100%;
    //         display: flex;
    //         align-items: center;
    //         .icon-points {
    //           width: 36px;
    //           height: 36px;
    //           margin-right: 12px;
    //         }
    //         .title {
    //           font-size: 30px;
    //           font-family: PingFang SC;
    //           font-weight: 300;
    //           color: #0f0f14;
    //         }
    //         .time {
    //           font-size: 24px;
    //           font-family: PingFang SC;
    //           font-weight: 300;
    //           color: #b9b9be;
    //           margin-top: 11px;
    //           font-style: normal;
    //         }
    //       }
    //       .income {
    //         font-size: 36px;
    //         font-family: PingFang SC;
    //         font-weight: 600;
    //         color: #fa5564;
    //       }
    //       .expend {
    //         font-size: 36px;
    //         font-family: PingFang SC;
    //         font-weight: 400;
    //         color: #b9b9be;
    //       }
    //     }
    //     .loading {
    //       font-size: 26px;
    //       font-family: PingFang SC;
    //       font-weight: 300;
    //       color: #b9b9be;
    //       display: flex;
    //       justify-content: center;
    //     }
    //   }
    // }
  }
}
