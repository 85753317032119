.dialogTip {
  .adm-mask {
    width: 100%;

    z-index: 999;
  }
  .adm-mask-content {
    width: 100%;
    height: 100%;
    // padding: 0 60px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
  }
  .common-dialog1 {
    width: calc(100% - 120px);
    margin: 0 auto;
    //   top: 50%;
    background: #fff;
    // height: 390px;
    background: #ffffff;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    padding: 50px 60px 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0f0f14;
      margin-bottom: 30px;
    }
    p {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 50px;
      color: #737378;
      text-align: left;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      height: 80px;
      margin-top: 30px;
      span:first-child {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9b9ba0;
        width: 270px;
        line-height: 80px;
        text-align: center;
      }
      span:last-child {
        width: 270px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        background: #64814d;
        border-radius: 40px;
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    //   margin-top: -180px;
  }
}
