.noAccess {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .adm-mask {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    // position: relative;
    .adm-mask-content {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .dialog {
      width: 100%;
      height: 550px;
      background: #ffffff;
      border-radius: 60px;
      position: absolute;
      top: 50%;
      margin-top: -275px;
      padding: 40px 30px;
      box-sizing: border-box;
      .close {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .adm-image {
          width: 25px;
          height: 25px;
        }
      }
      .cont {
        width: 100%;
        height: calc(100% - 25px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .adm-image {
          width: 180px;
          height: 170px;
          margin-bottom: 45px;
        }
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
        }
        h3 {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0f0f14;
          line-height: 40px;
          margin-top: 20px;
        }
      }
    }
  }
}
