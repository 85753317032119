.mine-wallet {
    color: #0F0F14;

    .wallet-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.67rem 0.5rem 1rem;
        margin: 0.02667rem 0 0.4rem;
        background: #FFF;
        box-shadow: 0 0.26rem 0.4rem 0 #EBEEF5;

        .wallet-icon {
            width: 1.33rem;
            height: 1.33rem;
            background: url("../../common/img/wallet-icon.png") 0 0 no-repeat;
            background-size: contain;
        }

        .wallet-name {
            margin-top: 0.2rem;
            font-size: 0.45rem;
        }

        .wallet-vender {
            font-size: 0.35rem;
            color: #737378;
        }

        .wallet-balance {
            margin-top: 0.3rem;
            font-size: 0.67rem;

            strong {
                font-size: 0.93rem;
            }
        }

        .wallet-info-botton {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 1.2rem;

            span {
                display: inline-block;
                width: 3.48rem;
                height: 1.06rem;
                line-height: 1.06rem;
                border-radius: 0.53rem;
                border: 2px solid #64814D;
                font-size: 0.43rem;
                text-align: center;
                color: #64814D;

                &.green {
                    background: #64814D;
                    color: #FFF;
                }
            }
        }
    }

    .wallet-nav {
        padding: 0 0.4rem;
        font-size: 0.4rem;
        background: #FFF;
        box-shadow: 0 0.26rem 0.4rem 0 #EBEEF5;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1.33rem;
            border-bottom: 2px solid #F5F5FA;

            .wallet-details {
                padding-left: 0.88rem;
                background: url("../../common/img/wallet-details.png") 0 center no-repeat;
                background-size: 22%;
            }

            .wallet-password {
                padding-left: 0.88rem;
                background: url("../../common/img/wallet-password.png") 0 center no-repeat;
                background-size: 15%;
            }

            .arrow {
                display: inline-block;
                width: 0.19rem;
                height: 0.33rem;
                margin: 0 0 0.1rem 0.2rem;
                vertical-align: middle;
                background: url("../../common/img/shop-info-right.png") 0 0 no-repeat;
                background-size: contain;
            }
        }
    }

    .recharge-list {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8.5rem;

        &::before {
            content: " ";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(101, 101, 101, 0.2);
        }

        .recharge-title {
            position: relative;
            line-height: 1.5rem;
            text-align: center;
            font-size: 0.4rem;
            border-radius: 0.53rem 0.53rem 0 0;
            background: #FFF;

            .recharge-close {
                position: absolute;
                top: 0.5rem;
                right: 0.6rem;
                display: inline-block;
                width: 0.4rem;
                height: 0.4rem;
                background: url("../../../components/SmsAreasCode/sms-close.png") 0 0 no-repeat;
                background-size: contain;
            }
        }

        ul {
            flex: 1;
            z-index: 1;
            background: #FFF;

            li {
                display: flex;
                justify-content: space-between;
                padding: 0.4rem 0;
                margin: 0 0.6rem;
                border-bottom: 1px solid #D7D7DC;
                font-size: 0.4rem;
                color: #737378;

                strong {
                    color: #0F0F14;
                    font-weight: normal;
                }

                .recharge-item-select {
                    width: 0.61rem;
                    height: 0.61rem;
                    background: url("../../common/img/select-no.png") 0 0 no-repeat;
                    background-size: contain;

                    &.select-ed {
                        background: url("../../common/img/select-ed.png") 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                &.recharge-pay1 {
                    padding-left: 1.5rem;
                    background: url("../../common/img/wallet-icon-pay1.png") 0 center no-repeat;
                    background-size: 13%;
                }

                &.recharge-pay2 {
                    padding-left: 1.5rem;
                    background: url("../../common/img/wallet-icon-pay2.png") 0.3rem center no-repeat;
                    background-size: 8%;
                }

                &.recharge-button {
                    justify-content: center;
                    height: 1.06rem;
                    line-height: 1.06rem;
                    padding: 0;
                    margin-top: 0.8rem;
                    border-radius: 0.53rem;
                    font-size: 0.43rem;
                    text-align: center;
                    background: #64814D;
                    color: #FFF;
                }
            }
        }
    }

    .wallet-recharge {
        flex: 1;

        input {
            width: 100%;
            padding: 0.3rem 0;
            border: none;
            font-size: 0.6rem;
            outline: none;

            &::-webkit-input-placeholder {
                font-size: 0.37rem;
                color: #9B9BA0;
            }
        }

        h3 {
            margin-bottom: 0.47rem;
            font-weight: normal;
            font-size: 0.37rem;
        }

        .recharge-button {
            justify-content: center;
            height: 1.06rem;
            line-height: 1.06rem;
            padding: 0;
            margin-top: 0.8rem;
            border-radius: 0.53rem;
            font-size: 0.43rem;
            text-align: center;
            background: #64814D;
            color: #FFF;

            &.disable {
                background: #acacac;
            }
        }

        &.wallet-recharge-1 {
            display: flex;
            flex-direction: column;

            .recharge-1-top {
                padding: 0.53rem 0.8rem;

                ul {
                    display: flex;
                    justify-content: space-between;

                    li {
                        width: 2.4rem;
                        height: 1.2rem;
                        line-height: 1.2rem;
                        border: 2px solid #D7D7DC;
                        border-radius: 0.27rem;
                        text-align: center;
                        font-size: 0.5rem;
                        color: #737378;

                        &.current {
                            border: 4px solid #64814D;
                            background: url("../../common/img/wallet-selected.png") right bottom no-repeat;
                            background-size: 18%;
                            color: #64814D;
                        }
                    }
                }
            }

            .recharge-1-bottom {
                flex: 1;
                padding: 0.8rem;
                box-shadow: 0 0.3rem 0.4rem 0 #EBEEF5;
                border-radius: 0.67rem 0.67rem 0 0;
                background: #FFF;

                input {
                    border-bottom: 1px solid #D7D7DC;
                }
            }
        }

        &.wallet-recharge-2 {
            padding: 0.5rem 1rem;
            text-align: center;
            background: #FFF;

            .rule {
                margin-top: 1rem;
                text-align: left;
                font-size: 0.35rem;
                border-top: 1px solid #F4F5FA;
                color: #737378;

                h4 {
                    padding: 0.6rem 0 0.2rem;
                    font-size: 0.4rem;
                    font-weight: normal;
                    color: #0F0F14;
                }

                p {
                    padding-bottom: 0.25rem;
                }
            }

            input {
                border: 1px solid #D7D7DC;
                border-radius: 0.27rem;
                text-align: center;
            }
        }
    }
}

.mine-wallet-details {
    background: #FFF;

    header {
        background: #64814D;

        .head {
            span {
                color: #FFF;
            }
        }
    }

    .wallet-details-info {
        padding-bottom: 0.66rem;
        font-size: 0.4rem;
        text-align: center;
        background: #64814D;
        color: #FFF;

        .wallet-balance {
            font-size: 0.66rem;

            strong {
                font-size: 0.93rem;
            }
        }

        .wallet-name {
            opacity: 0.6;
        }
    }

    .wallet-details-nav {
        display: flex;
        justify-content: space-between;
        padding: 0.27rem 0.4rem 0.28rem;
        background: #F5F5FA;

        li {
            width: 2.92rem;
            height: 0.92rem;
            line-height: 0.92rem;
            font-size: 0.4rem;
            text-align: center;
            border: 2px solid #64814D;
            border-radius: 0.46rem;
            background: #FFF;
            color: #64814D;

            &.current {
                position: relative;
                background: #64814D;
                color: #FFF;

                &::after {
                    content: " ";
                    position: absolute;
                    bottom: -0.1rem;
                    left: 50%;
                    width: 0.4rem;
                    height: 0.1rem;
                    margin-left: -0.2rem;
                    background: url("../../common/img/wallet-nav-down.png") 0 0 no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    .wallet-details-list {
        padding: 0 0.4rem;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.45rem 0 0.45rem 0.86rem;
            border-bottom: 2px solid #F5F5FA;
            font-size: 0.4rem;
            background: url("../../common/img/wallet-icon2.png") 0 center no-repeat;
            background-size: 0.53rem 0.53rem;
            color: #0F0F14;

            &:last-child {
                border-bottom: none;
            }

            span {
                display: block;
                font-size: 0.32rem;
                color: #B9B9BE;
            }

            .balance {
                font-size: 0.45rem;
                color: #737378;
            }

            &.plus {
                background-image: url("../../common/img/wallet-icon1.png");

                .balance {
                    color: #64814D;
                }
            }
        }

        .loading {
            background: none;
            padding: 0;
            margin-top: 1rem;
            font-size: 0.35rem;
            font-family: PingFang SC;
            font-weight: 300;
            color: #b9b9be;
            display: flex;
            justify-content: center;
        }
    }

    .wallet-code {
        flex: 1;
        padding: 0 0.4rem 0.4rem;
        text-align: center;
        background: #64814D;

        svg {
            width: 80%;
            height: 2.5rem;
        }

        .wallet-code-title {
            padding: 0 0 0.5rem;
        }

        .wallet-code-top {
            padding: 0.4rem;
            margin-bottom: 0.8rem;
            border-radius: 0.53rem;
            background: #FFF;

            .wallet-code-qrcode {
                margin: 0 auto;
                margin-top: 0.6rem;
            }

            .wallet-code-time {
                margin-top: 0.4rem;
                font-size: 0.35rem;
                color: #737378;
            }
        }

        .wallet-code-bottom {
            padding: 0.4rem;
            border-radius: 0.53rem;
            background: #FFF;
        }
    }
}

.mine-wallet-password {

    .password-item {
        flex: 1;
        padding: 0.4rem;
        margin: 0.02667rem 0 0;
        background: #FFF;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.74rem 0 0.27rem;
            border-bottom: 2px solid #F5F5FA;
            font-size: 0.38rem;

            input {
                flex: 1;
                height: 0.59rem;
                font-size: 0.38rem;
                border: none;
                outline: none;
            }

            span {
                width: 0.48rem;
                height: 0.59rem;
                margin: 0 0.4rem 0 0.1rem;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;

                &.wallet-icon-phone {
                    margin-right: 0.133rem;
                    background-image: url("../../common/img/wallet-icon-phone.png");
                }

                &.wallet-icon-code {
                    background-image: url("../../common/img/wallet-icon-code.png");
                }

                &.wallet-icon-password {
                    background-image: url("../../common/img/wallet-icon-password.png");
                }

                &.wallet-icon-hide {
                    background-image: url("../../common/img/wallet-icon-hide.png");
                }

                &.wallet-icon-show {
                    background-image: url("../../common/img/wallet-icon-show.png");
                }
            }

            .country-code {
                position: relative;
                width: 1.33rem;
                height: 0.67rem;
                line-height: 0.67rem;
                margin-right: 0.52rem;
                border-radius: 0.133rem;
                text-align: center;
                font-style: normal;
                white-space: nowrap;
                background: #F5F5FA;
                color: #737378;

                &::after {
                    content: " ";
                    position: absolute;
                    right: -0.21rem;
                    width: 0.03rem;
                    height: 0.67rem;
                    background: #F5F5FA;
                }
            }

            .phone-input {
                width: 1rem;
            }

            .get-code {
                white-space: nowrap;
                font-style: normal;
                color: #1987EB;
            }

            .interval-time {
                padding: 0.1rem 0.3rem;
                border-radius: 0.3rem;
                font-style: normal;
                white-space: nowrap;
                background: #F5F5FA;
                color: #737378;
            }
        }

        .password-botton {
            height: 1.06rem;
            line-height: 1.06rem;
            margin-top: 1.33rem;
            border-radius: 0.53rem;
            font-size: 0.43rem;
            text-align: center;
            background: #64814D;
            color: #FFF;

            &.disable {
                background: #D7D7DC;
            }
        }

        .password-tips {
            height: 0.5rem;
            margin: 0.27rem 0 0 0.1rem;
            color: #FA5564;
        }
    }
}