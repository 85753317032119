.store-comment-detail {
  width: 100%;
  //   position: relative;
  overflow-x: hidden;
  padding-top: 231px;
  box-sizing: border-box;
  background-color: #f5f5fa;
  header {
    width: 100%;
    background-color: #fff;
    margin-bottom: 1px;
    position: fixed;
    top: 0;
    .head {
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      box-sizing: border-box;
      img {
        width: 40px;
        height: 40px;
        display: block;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
      }
    }
    .choice {
      width: 100%;
      height: 120px;
      padding: 0 35px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737378;
        height: 60px;
        align-items: center;
        display: flex;
        border: 1px solid #d7d7dc;
        border-radius: 20px;
        padding: 0 14px;
        box-sizing: border-box;
        flex-shrink: 0;
        max-width: 24%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        b {
          font-weight: normal;
          flex-shrink: 0;
        }
        em {
          font-style: normal;
          flex-shrink: 0;
        }
      }
      .active {
        color: #ffaf64;
        border: 1px solid #ffc35f;
      }
    }
  }
  .comment-box {
    background-color: #fff;
    padding: 25px 30px 70px;
    box-sizing: border-box;
    .title {
      width: 100%;
      background: url(../storeDetail/img/douhao.png) no-repeat left center;
      height: 80px;
      padding-left: 30px;
      background-size: 62px 80px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
        em {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #737378;
          font-style: normal;
          margin-left: 19px;
        }
      }
      .content-detail {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737378;
        font-style: normal;
        img {
          width: 14px;
          height: 26px;
          margin-left: 17px;
        }
      }
    }
    .comments-content {
      width: 100%;
      padding: 0 30px;
      margin-top: 35px;
      > div {
        margin-bottom: 50px;
        .head {
          width: 100%;
          display: flex;
          align-items: center;
          .head-photo {
            width: 54px;
            height: 54px;
            border-radius: 100%;
            display: block;
            margin-right: 16px;
          }
          .nickname {
            padding: 1px 0;
            box-sizing: border-box;
            span {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #737378;
            }
            .stars {
              margin-top: 7px;
              img {
                width: 21px;
                height: 20px;
                margin-right: 4px;
              }
            }
          }
        }
        .content {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 40px;
          margin-top: 26px;
        }
        .images {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;
          .head-photo {
            width: 300px;
            height: 300px;
            border-radius: 10px;
            display: block;
            margin-bottom: 20px;
          }
          div {
            width: 300px;
            height: 300px;
            border-radius: 10px;

            background-color: #737378;
          }
        }
        .many {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;

          .head-photo {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            display: block;
            margin-bottom: 20px;
          }
          .head-photo:not(:nth-child(3n)) {
            margin-right: 15px;
          }
          div {
            width: 200px;
            height: 200px;
            border-radius: 10px;
          }
        }

        p {
          margin-top: 19px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #64646f;
        }
      }
      .loading {
        width: 100%;
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          width: 116px;
          height: 64px;
          margin-bottom: 19px;
        }
        span {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ac9685;
          line-height: 40px;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .choice {
    padding: 0 20px !important;

    span {
      font-size: 22px !important;
    }
  }
}
