.mine-invoice {
    .head {
        em {
            font-style: normal;
            font-size: 0.4rem;
        }
    }

    .list {
        background: #F5F5FA;
        color: #737378;
        font-family: "PingFang SC";

        .invoice-tips {
            margin: 0.4rem 0 0.48rem;
            text-align: center;
            font-size: 0.32rem;
        }

        .invoice-list {

            li {
                margin-bottom: 0.4rem;

                .invoice-order-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.35rem 0.4rem;
                    background: #FAFAFC;
                    box-shadow: 0 0.53rem 0.4rem 0 #EBEEF5;
                    border-radius: 0 0 0.53rem 0.53rem;

                    p {
                        color: #0F0F14;

                        strong {
                            font-size: 0.48rem;
                        }

                        span {
                            display: inline-block;
                            width: 0.19rem;
                            height: 0.33rem;
                            margin: 0 0 0.15rem 0.2rem;
                            vertical-align: middle;
                            background: url("../../common/img/shop-info-right.png") 0 0 no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }

        }
    }
}

.invoice-order-info {
    padding: 0.53rem 0.4rem;
    margin-bottom: 2px;
    background: #FFF;
    box-shadow: 0 0.27rem 0.4rem 0 #EBEEF5;
    border-radius: 0.53rem 0.53rem 0 0;

    .invoice-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.7rem;
        font-size: 0.4rem;

        strong {
            color: #0F0F14;
        }

        em {
            display: inline-block;
            width: 0.43rem;
            height: 0.4rem;
            margin: 0 0.19rem 0.1rem 0;
            vertical-align: middle;
            background: url("../../common/img/invoice-icon.png") 0 0 no-repeat;
            background-size: contain;

        }
    }

    .product-list {
        display: flex;
        align-items: center;

        .product-item {
            display: flex;
            align-items: center;

            .product-img {
                width: 1.6rem;
                height: 1.6rem;
                margin-right: 0.3rem;
                border-radius: 0.13rem;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .product-name {
                display: none;
            }
        }

        .product-num {
            font-size: 0.4rem;
        }

        &.product-list-1 {
            .product-item {
                .product-name {
                    flex: 1;
                    font-size: 0.37rem;
                    color: #0F0F14;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

        &.product-list-more {
            .product-item:nth-child(n+4) {
                display: none;
            }
        }
    }
}

.mine-invoice-detail {
    header {
        background: #64814D;

        .head {
            span {
                color: #FFF;
            }
        }
    }

    .list {
        padding: 0;
        margin-top: 0;
        margin-bottom: 3rem;
        background: #F5F5FA;
        color: #0F0F14;
        font-family: "PingFang SC";

        .invoice-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 0.4rem 2.2rem 0.8rem;
            background: #64814D;
            color: #FFF;

            .invoice-status-left {
                display: flex;
                align-items: center;
                line-height: 0.5rem;
                font-size: 0.32rem;

                span {
                    display: inline-block;
                    width: 0.9rem;
                    height: 0.9rem;
                    margin-right: 0.4rem;

                    &.invoice-ed {
                        background: url("../../common/img/invoice-icon1.png") 0 0 no-repeat;
                        background-size: contain;
                    }

                    &.invoice-ing {
                        background: url("../../common/img/invoice-icon2.png") 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                strong {
                    font-size: 0.45rem;
                }

                em {
                    font-size: 0.45rem;
                    font-style: normal;
                }
            }

            .invoice-status-right {
                width: 2.6rem;
                height: 1.6rem;
                border-radius: 0.13rem;
                background: #F5F5FA;

                &.invoice-ed {
                    background: #F5F5FA url("../../common/img/invoice-img.png") center center no-repeat;
                    background-size: contain;
                }

                &.invoice-ing {
                    background: #F5F5FA url("../../common/img/invoice-icon3.png") center center no-repeat;
                    background-size: 35%;
                }
            }
        }

        .invoice-info {
            padding: 0.4rem 0.4rem 0.8rem;
            margin: -1.5rem 0.4rem 0;
            line-height: 0.7rem;
            font-size: 0.37rem;
            border-radius: 0.4rem;
            background: #FFF;

            h3 {
                margin: 0.1rem 0 0.4rem;
                font-size: 0.43rem;
                text-align: center;
            }

            em {
                font-style: normal;
                font-weight: 400;
            }
        }

        .invoice-order {
            margin: 0 0.4rem;

            .invoice-order-title {
                margin: 0.8rem 0 0.4rem 0.4rem;
                font-size: 0.4rem;
            }

            .invoice-order-info {
                border-radius: 0.53rem;

                .invoice-name {
                    margin-bottom: 0.3rem;
                }
            }

            .invoice-time {
                margin-top: 0.3rem;
                font-size: 0.32rem;
                color: #737378;
            }
        }

        .invoice-button {
            display: flex;
            justify-content: space-between;
            position: fixed;
            right: 0;
            bottom: 0.93rem;
            left: 0;
            margin: 0 0.4rem;

            span {
                display: inline-block;
                width: 4.46rem;
                height: 1.06rem;
                line-height: 1.06rem;
                border-radius: 0.53rem;
                border: 2px solid #D7D7DC;
                font-size: 0.45rem;
                text-align: center;
                background: #F5F5FA;
                color: #9B9BA0;

                &.green {
                    border-color: #64814D;
                    background: #64814D;
                    color: #FFF;
                }
            }
        }

    }
}

.invoice-none {
    padding-top: 2rem;
    text-align: center;
    font-size: 0.4rem;
    color: #9B9BA0;
}