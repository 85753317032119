.mapContainer {
  width: 100%;
  height: 100%;

  .head {
    width: 100%;
    height: 110px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 40px;
      height: 40px;
    }
    span {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333;
    }
  }
  #container {
    width: 100%;
    height: calc(100% - 110px);
  }

  .amap-icon {
    img {
      width: 82px;
      height: 96px;
    }
  }
  .amap-marker-label {
    background: #64814d;
    border: 4px solid #ffffff;
    border-radius: 10px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
  }
}
