.membershipCard-detail {
  width: 100%;
  height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  padding-bottom: 240px;
  overflow-y: scroll;

  padding-top: 110px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 110px;
    line-height: 110px;
    position: fixed;
    top: 0;
    // background: url(./img/header-bg.png) no-repeat center center;
    // background-size: cover;
    // padding: 35px 0;
    // height: 300px;
    // box-sizing: border-box;
    background-color: #fff;
    z-index: 999;
    .head {
      width: 100%;
      height: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
      }
    }
  }
  .card-box {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin-top: 30px;
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 230px;
        border-radius: 30px;
        overflow: hidden;
        width: 100%;
        margin-bottom: 40px;
        .left {
          height: 100%;
          background: #ffd2aa;
          width: 230px;
          display: flex;
          flex-direction: column;
          padding: 25px 0 30px;
          box-sizing: border-box;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
            display: block;
          }
          p {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #b4503c;
            margin-top: 9px;
          }
          span {
            background: #ffffff;
            opacity: 0.5;
            border-radius: 20px;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #c87864;
            padding: 0px 24px;
            box-sizing: border-box;
            margin-top: 30px;
          }
        }
        .right {
          flex: 1;
          height: 100%;
          background-color: #fff;
          padding: 19px 30px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          p {
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #8c645a;
          }
          .right-b {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .price {
              display: flex;
              flex-direction: column;
              span {
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #0f0f14;
                line-height: 36px;
                em {
                  color: #fa5564;
                  font-style: normal;
                  margin-left: 10px;
                }
              }
            }
            .btn {
              background: #fa5564;
              border-radius: 30px;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #fff0aa;
              line-height: 35px;
              padding: 15px 40px;
            }
          }
        }
        //   border-bottom: 1px solid #f5f5fa;
        //   .points-left {
        //     height: 100%;
        //     display: flex;
        //     align-items: center;
        //     .icon-points {
        //       width: 36px;
        //       height: 36px;
        //       margin-right: 12px;
        //     }
        //     .title {
        //       font-size: 30px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #0f0f14;
        //     }
        //     .time {
        //       font-size: 24px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #b9b9be;
        //       margin-top: 11px;
        //       font-style: normal;
        //     }
        //   }
        //   .income {
        //     font-size: 36px;
        //     font-family: PingFang SC;
        //     font-weight: 600;
        //     color: #fa5564;
        //   }
        //   .expend {
        //     font-size: 36px;
        //     font-family: PingFang SC;
        //     font-weight: 400;
        //     color: #b9b9be;
        //   }
      }
      .loading {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #b9b9be;
        display: flex;
        justify-content: center;
      }
    }
  }
  .card-detail {
    width: 100%;
    padding: 0 30px;
    .layout {
      background: #ffffff;
      box-shadow: 0px 20px 30px 0px #ebeef5;
      border-radius: 40px;
      padding: 40px 30px;
      box-sizing: border-box;
      h1 {
        height: 40px;
        line-height: 40px;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fb8122;
        text-align: center;
        margin-bottom: 10px;
      }
      .title {
        width: 100%;
        position: relative;
        height: 40px;
        line-height: 40px;
        .line {
          width: 100%;
          height: 2px;
          background: #f5f5fa;
          position: absolute;
          display: block;
          left: 0;
          top: 50%;
          margin-top: -1px;
        }
        em {
          position: absolute;
          display: block;
          left: 50%;
          background-color: #fff;
          font-style: normal;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          width: 150px;
          margin-left: -75px;
          top: 50%;
          margin-top: -20px;
          z-index: 2;
          text-align: center;
        }
      }
      p {
        font-size: 26px !important;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14 !important;
        line-height: 40px !important;
        margin-top: 40px !important;
      }
    }
  }
  footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff !important;
    box-sizing: border-box;
    z-index: 999;
    .agreement {
      padding: 0 30px 0 60px;
      height: 70px;
      display: flex;
      align-items: center;
      background-color: #fff;
      margin-bottom: 2px;
      .adm-checkbox-custom-icon {
        .adm-image {
          width: 36px;
          height: 36px;
        }
      }
      span {
        margin-left: 20px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        line-height: 36px;
        em {
          font-style: normal;
          color: #1987eb;
        }
      }
    }
    .price {
      padding: 18px 30px 70px 60px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fa5564;
        em {
          font-size: 50px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #fa5564;
          margin-left: 8px;
          font-style: normal;
        }
      }
      p {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
      }
      .btn {
        width: 350px;
        height: 80px;
        background: #fa5564;
        border-radius: 40px;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 80px;
      }
      // height: 80px;
    }
  }
  .adm-mask {
    .adm-mask-content {
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .hint {
        // position: absolute;
        // top: 10%;

        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        z-index: 999;
        .layout {
          background: #ffffff;
          border-radius: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px 0;
          h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            .adm-image {
              width: 36px;
              height: 36px;
              img {
                width: 36px;
                height: 36px;
              }
            }
          }
          p {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #0f0f14;
            line-height: 40px;
            margin: 66px 0 70px 0;
            padding: 0 60px;
            box-sizing: border-box;
            span {
              color: #fa5564;
            }
          }
          .btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 30px;
            box-sizing: border-box;
            .cancel {
              width: 270px;
              height: 80px;
              border: 2px solid #d7d7dc;
              border-radius: 40px;
              font-size: 34px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #9b9ba0;
              line-height: 80px;
              text-align: center;
              display: block;
              margin-right: 30px;
            }
            .transfer {
              width: 270px;
              height: 80px;
              background: #64814d;
              border-radius: 40px;
              font-size: 34px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              line-height: 80px;
            }
          }
        }
      }
      .card {
        background-color: #fff;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 50px 50px 0px 0px;
        padding: 40px 0;
        h1 {
          display: flex;
          width: 100%;
          align-items: center;
          padding: 0 30px 0 50px;
          box-sizing: border-box;
          justify-content: space-between;

          span {
            font-size: 34px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
          }
          img {
            width: 25px;
            height: 25px;
          }
        }
        .list {
          .adm-space {
            width: 100%;
            padding: 60px 30px 40px 17px;
            .adm-space-item {
              width: 100%;
              margin-bottom: 30px;
              .adm-radio {
                display: flex;
                align-items: center;

                .adm-radio-custom-icon {
                  .adm-image {
                    width: 46px;
                    .adm-image-img {
                      width: 46px;
                      height: 46px;
                    }
                  }
                }
                .adm-radio-content {
                  margin-left: 17px;
                  .cont {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    overflow: hidden;
                    height: 150px;
                    .left {
                      width: 210px;
                      height: 100%;
                      background: #ff6e6e;
                      border-radius: 10px 0px 0px 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      span {
                        font-size: 40px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #ffffff;
                        em {
                          font-size: 54px;
                          font-style: normal;
                        }
                      }
                    }
                    .right {
                      width: 430px;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      background: #fafafc;
                      padding-left: 30px;
                      box-sizing: border-box;
                      h2 {
                        font-size: 32px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #0f0f14;
                      }
                      p {
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 300;
                        color: #737378;
                        margin-top: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
          .btn {
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            span {
              width: 100%;
              height: 80px;
              font-size: 32px;
              font-family: PingFang SC;
              background: #fa5564;
              border-radius: 40px;
              font-weight: 400;
              color: #ffffff;
              line-height: 80px;
              text-align: center;
              display: block;
            }
          }
        }
      }
    }
  }
}
