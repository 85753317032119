.mineAddressList {
  width: 100%;
  min-height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 110px;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    top: 0;
    img {
      width: 40px;
      height: 40px;
    }
    p {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
    }
  }

  .address-list {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    flex: 1;
    padding-bottom: 144px;
    .address-item {
      width: 100%;
      background: #ffffff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding: 40px 30px;
      box-sizing: border-box;
      margin-bottom: 30px;
      .address {
        width: 32px;
        height: 36px;
      }
      .cont {
        flex: 1;
        margin: 0 62px 0 29px;
        p {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 40px;
          span {
            border: 1px solid #fa5564;
            border-radius: 10px;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fa5564;
            line-height: 40px;
            padding: 0 10px;
            box-sizing: border-box;
            margin-right: 6px;
          }
        }
        div {
          display: flex;
          margin-top: 29px;
          span {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            margin-right: 40px;
          }
        }
      }
      .edit {
        width: 26px;
        height: 26px;
      }
    }
    // div {
    //   width: 100%;
    //   border-bottom: solid 1px #d7d7dc;
    //   padding: 35px 0;
    // }
    // .adm-space {
    //   width: 100%;
    //   .adm-space-item {
    //     border-bottom: solid 1px #d7d7dc;
    //     padding: 35px 0;
    //     .adm-radio {
    //       width: 100%;
    //       padding: 0 30px;
    //       box-sizing: border-box;
    //       .adm-radio-custom-icon {
    //         width: 26px;
    //         height: 18px;
    //         .adm-image {
    //           width: 26px;
    //           height: 18px;
    //           margin-right: 3px;
    //         }
    //       }
    //       .adm-radio-content {
    //         .address {
    //           font-size: 30px;
    //           font-family: PingFang SC;
    //           font-weight: 400;
    //           color: #0f0f14;
    //           line-height: 40px;
    //         }
    //         .info {
    //           width: 100%;
    //           display: flex;
    //           align-items: center;
    //           font-size: 28px;
    //           font-family: PingFang SC;
    //           font-weight: 300;
    //           color: #737378;
    //           margin-top: 29px;
    //           .phone {
    //             margin-left: 50px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .footer {
    width: 100%;
    border-top: solid 2px #f5f5fa;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 144px;
    padding: 22px 30px;
    box-sizing: border-box;
    background-color: #fff;
    button {
      width: 100%;
      height: 80px;
      background: #fa5564;
      border-radius: 40px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      border: none;
      outline: none;
    }
  }
}
