.evaluatePage {
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    // background: url(./img/header-bg.png) no-repeat center center;
    // background-size: cover;
    // padding: 35px 0;
    background-color: #fff;
    .head {
      width: 100%;
      height: 40px;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;

        color: #0f0f14;
      }
    }
  }
  .banner {
    width: 100%;
    height: 290px;
    .adm-image {
      width: 100%;
      height: 100%;
    }
  }
  .classify {
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    > .adm-tabs-header {
      height: 110px;
      background-color: #fff;
      .adm-tabs-tab-list {
        height: 110px;
        .adm-tabs-tab-wrapper {
          //   display: flex;
          //   justify-content: center;

          .adm-tabs-tab {
            height: 100%;
            // margin-left: 0;
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #737378;
            box-sizing: border-box;
          }
        }
      }
      .adm-tabs-tab-line {
        height: 4px;
        background: #ffd345;
        border-radius: 2px;
        width: 90px;
      }
    }
    .adm-tabs-content {
      padding: 0;
    }
  }
  .list {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    padding: 0 30px;
    .collect-box {
      width: 100%;
      ul {
        // li {
        //   width: 100%;
        //   display: flex;
        //   align-items: center;
        //   height: 260px;
        //   margin-top: 60px;
        //   .img {
        //     width: 260px;
        //     height: 260px;
        //     position: relative;
        //     margin-right: 30px;
        //     flex-shrink: 0;
        //     .mask {
        //       width: 100%;
        //       height: 100%;
        //       background: #96b4e1;
        //       opacity: 0.5;
        //       border-radius: 10px;
        //       position: absolute;
        //       left: 0;
        //       top: 0;
        //       z-index: 2;
        //       span {
        //         background: #000000;

        //         height: 50px;
        //         display: block;
        //         bottom: 0;
        //         left: 0;
        //         border-radius: 0px 0px 10px 10px;
        //         position: absolute;
        //         width: 100%;
        //         font-size: 26px;
        //         font-family: PingFang SC;
        //         font-weight: 300;
        //         color: #ffffff;
        //         line-height: 50px;
        //         text-align: center;
        //       }
        //     }
        //     .adm-image {
        //       width: 100%;
        //       height: 100%;
        //       .adm-image-img {
        //         display: block;
        //         height: 100%;
        //         border-radius: 4px 6px 6px 4px;
        //       }
        //     }
        //   }
        //   .cont {
        //     width: calc(100% - 290px);
        //     padding: 10px 0;
        //     box-sizing: border-box;
        //     .name {
        //       font-size: 32px;
        //       font-family: PingFang SC;
        //       font-weight: 400;
        //       color: #0f0f14;
        //       text-overflow: ellipsis;
        //       overflow: hidden;
        //       white-space: nowrap;
        //       margin-bottom: 10px;
        //     }
        //     .price {
        //       display: flex;
        //       align-items: center;
        //       .now {
        //         font-size: 40px;
        //         font-family: PingFang SC;
        //         font-weight: 600;
        //         color: #fa5564;
        //         line-height: 40px;
        //         margin-right: 19px;
        //         em {
        //           font-size: 30px;
        //           font-family: PingFang SC;
        //           font-weight: 600;
        //           color: #fa5564;
        //           line-height: 40px;
        //           margin-right: 9px;
        //           font-style: normal;
        //         }
        //       }
        //       .original {
        //         font-size: 28px;
        //         font-family: PingFang SC;
        //         font-weight: 300;
        //         color: #737378;
        //       }
        //     }
        //     .des {
        //       width: 100%;
        //       font-size: 26px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #b9b9be;
        //       line-height: 34px;
        //       margin-top: 10px;
        //       p {
        //         text-overflow: ellipsis;
        //         overflow: hidden;
        //         white-space: nowrap;
        //       }
        //     }
        //     .comment {
        //       font-size: 26px;
        //       font-family: PingFang SC;
        //       font-weight: 300;
        //       color: #737378;
        //       line-height: 34px;
        //       margin-top: 15px;
        //       span {
        //         margin-right: 20px;
        //       }
        //     }
        //   }
        // }
        .loading {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #b9b9be;
          display: flex;
          justify-content: center;
          margin: 30px 0;
        }
      }
    }
    .nav {
      width: 100%;
      // height: 120px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
      li {
        width: 220px;
        height: 70px;
        background: #ffffff;
        border: 2px solid #d7d7dc;
        border-radius: 35px;
        // padding: 0 80px;
        line-height: 70px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737378;
        text-align: center;
      }
      .active {
        background: #fa5564;
        border: none;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: relative;
      }
      .active:before {
        position: absolute;
        display: block;
        content: "";
        left: 50%;
        margin-left: -15px;
        background: url(./img/active.png) no-repeat center center;
        background-size: cover;
        width: 31px;
        height: 8px;
        bottom: -8px;
      }
    }
    .cont {
      .good-item {
        display: flex;
        height: 280px;
        background: #ffffff;
        box-shadow: 0px 20px 30px 0px #ebeef5;
        border-radius: 40px;
        padding: 40px 30px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 30px;
        .adm-image {
          width: 200px;
          border-radius: 10px;
          margin-right: 24px;
        }

        .des {
          width: calc(100% - 200px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          h1 {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
          }
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
            em {
              font-style: normal;
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #b9b9be;
              line-height: 30px;
            }
            span {
              width: 130px;
              height: 60px;
              border: 2px solid #fa5a64;
              border-radius: 35px;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #fa5a64;
              text-align: center;
              line-height: 60px;
            }
          }
        }
      }
    }
  }
  .evaluated {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    .item {
      background: #ffffff;
      box-shadow: 0px 20px 30px 0px #ebeef5;
      border-radius: 40px;
      padding: 40px 0;
      margin-bottom: 30px;
      box-sizing: border-box;
      .time {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #64646f;
        margin-bottom: 20px;
        padding: 0 30px;
      }
      .stars {
        padding: 0 30px;
        img {
          width: 36px;
          height: 34px;
          margin-right: 6px;
        }
      }
      .comment {
        margin-top: 39px;
        .content {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 40px;
          padding: 0 30px;
        }
        // .images {
        //   padding: 0 30px;
        //   width: 100%;
        //   display: flex;
        //   justify-content: space-between;
        //   flex-wrap: wrap;
        //   margin-top: 30px;
        //   img {
        //     width: 300px;
        //     height: 300px;
        //     border-radius: 10px;
        //     display: block;
        //   }
        //   div {
        //     width: 300px;
        //     height: 300px;
        //     border-radius: 10px;

        //     background-color: #737378;
        //   }
        // }
        .images {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;
          padding: 0 30px;
          box-sizing: border-box;
          .head-photo {
            width: 300px;
            height: 300px;
            border-radius: 10px;
            display: block;
          }
          div {
            width: 300px;
            height: 300px;
            border-radius: 10px;
          }
        }
        .many {
          width: 100%;
          display: flex;
          padding: 0 30px;

          flex-wrap: wrap;
          margin-top: 30px;
          box-sizing: border-box;
          .head-photo {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            display: block;
            margin-bottom: 20px;
            // margin-bottom: ;
          }
          .head-photo:not(:nth-child(3n)) {
            margin-right: 15px;
          }
          div {
            width: 200px;
            height: 200px;
            border-radius: 10px;
          }
        }
        .product {
          width: 100%;
          height: 160px;
          display: flex;
          align-items: center;
          background: #fafafc;
          padding: 0 30px;
          margin-top: 40px;
          .adm-image {
            width: 120px;
            height: 120px;
          }
          p {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            margin-left: 24px;
          }
        }
        .view {
          width: 100%;
          padding: 0 30px;
          display: flex;
          align-items: center;
          margin-top: 31px;
          .adm-image {
            width: 44px;
            height: 26px;
            margin-right: 12px;
          }
          span {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #64646f;
            margin-right: 20px;
          }
          em {
            font-style: normal;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #64646f;
          }
        }
      }
    }
    .loading {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #b9b9be;
      display: flex;
      justify-content: center;
      margin: 30px 0;
    }
  }
}
