.li-item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 260px;
  margin-top: 60px;
  .img {
    width: 260px;
    height: 260px;
    position: relative;
    margin-right: 30px;
    flex-shrink: 0;
    .mask {
      width: 100%;
      height: 100%;
      // background: #96b4e1;
      // opacity: 0.5;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      span {
        background: #000000;

        height: 50px;
        display: block;
        bottom: 0;
        left: 0;
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        width: 100%;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }
    .adm-image {
      width: 100%;
      height: 100%;
      border-radius: 4px 6px 6px 4px;
      .adm-image-img {
        display: block;
        height: 100%;
        border-radius: 4px 6px 6px 4px;
      }
    }
  }
  .cont {
    width: calc(100% - 290px);
    padding: 10px 0;
    box-sizing: border-box;
    .name {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 10px;
    }
    .price {
      display: flex;
      align-items: center;
      .now {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fa5564;
        line-height: 40px;
        margin-right: 19px;
        em {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #fa5564;
          line-height: 40px;
          margin-right: 9px;
          font-style: normal;
        }
      }
      .original {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #737378;
        text-decoration: line-through;
      }
    }
    .des {
      width: 100%;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #b9b9be;
      line-height: 34px;
      margin-top: 10px;
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .comment {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #737378;
      line-height: 34px;
      margin-top: 15px;
      span {
        margin-right: 20px;
      }
    }
  }
}
