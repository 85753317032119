.depisut-root {
    height: 100%;

    .App {
        height: 100%;
    }
}

.mine-deposit {
    .list {
        background: #F5F5FA;
        font-family: "PingFang SC";

        .deposit-step1 {
            padding: 0.4rem;
            margin: 0.4rem 0 2.5rem;
            line-height: 0.54rem;
            border-radius: 0.45rem;
            font-size: 0.35rem;
            background: #FFF;
            color: #737378;

            .step1-top {
                margin-bottom: 1rem;
                text-align: center;
                border-bottom: 1px solid #F5F5FA;

                .top-icon {
                    width: 0.93rem;
                    height: 0.93rem;
                    margin: 0.2rem auto;
                    background: url("../../common/img/success-icon.png") 0 0 no-repeat;
                    background-size: contain;
                }

                .top-title {
                    margin-bottom: 0.3rem;
                    font-size: 0.36rem;
                    color: #0F0F14;
                }

                .top-money {
                    font-size: 0.39rem;
                    color: #FA5564;

                    strong {
                        font-size: 0.65rem;
                    }
                }

                .top-get {
                    color: #FA5564;
                }

                .top-look {
                    margin: 1rem 0 0.3rem;

                    span {
                        display: inline-block;
                        width: 0.19rem;
                        height: 0.33rem;
                        margin: 0 0 0.1rem 0.2rem;
                        vertical-align: middle;
                        background: url("../../common/img/shop-info-right.png") 0 0 no-repeat;
                        background-size: contain;
                    }
                }
            }

            .step1-bottom {
                h3 {
                    margin: 0.2rem 0 0.28rem;
                    font-size: 0.4rem;
                    color: #0F0F14;
                }

                p {
                    padding-bottom: 0.28rem;
                }

                .red {
                    color: #FA5564;
                }

                strong {
                    font-weight: normal;
                    color: #0F0F14;
                }
            }

            .deposit-list {
                li {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 0.45rem;
                    margin-bottom: 0.45rem;
                    border-bottom: 1px solid #F5F5FA;
                    font-size: 0.4rem;
                    color: #0F0F14;

                    &:last-child {
                        border-bottom: none;
                    }

                    span {
                        display: block;
                        font-size: 0.32rem;
                        color: #B9B9BE;

                        &.red {
                            color: #FA5564;
                        }
                    }

                    .list-right {
                        text-align: right;

                        span {
                            font-size: 0.37rem;
                        }
                    }
                }

                .loading {
                    margin-top: 1rem;
                    font-size: 0.35rem;
                    font-family: PingFang SC;
                    font-weight: 300;
                    color: #b9b9be;
                    display: flex;
                    justify-content: center;
                }
            }

        }

        .borrow-list {
            margin: 0.4rem 0 2.5rem;

            .borrow-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3.33rem;
                margin-bottom: 0.4rem;

                .borrow-item-select {
                    width: 0.83rem;
                    margin-right: 0.23rem;
                }

                .borrow-item-select {
                    width: 0.61rem;
                    height: 0.61rem;
                    background: url("../../common/img/select-no.png") 0 0 no-repeat;
                    background-size: contain;

                    &.select-disable {
                        background: url("../../common/img/select-disable.png") 0 0 no-repeat;
                        background-size: contain;
                    }

                    &.select-ed {
                        background: url("../../common/img/select-ed.png") 0 0 no-repeat;
                        background-size: contain;
                    }
                }

                .borrow-item-left {
                    display: flex;
                    width: 2.93rem;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.37rem;
                    border-radius: 0.13rem 0 0 0.13rem;
                    background-color: #FF6E6E;
                    color: #FFF;

                    p {
                        font-size: 0.5rem;

                        strong {
                            font-size: 0.8rem;
                        }
                    }
                }

                .borrow-item-right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    line-height: 0.5rem;
                    padding: 0.3rem 0.33rem 0.33rem 0.4rem;
                    font-size: 0.32rem;
                    border-radius: 0 0.13rem 0.13rem 0;
                    background: #FFF;
                    color: #0F0F14;

                    .borrow-item-name {
                        flex: 1;
                        font-size: 0.43rem;
                        color: #A18873;
                    }

                    em {
                        font-style: normal;
                        color: #FF6E6E;
                    }
                }
            }

            .none {
                margin-top: 1rem;
                font-size: 0.35rem;
                font-family: PingFang SC;
                font-weight: 300;
                color: #b9b9be;
                display: flex;
                justify-content: center;
            }
        }

        .step-button {
            position: fixed;
            bottom: 0.8rem;
            right: 0.4rem;
            left: 0.4rem;
            height: 1rem;
            line-height: 1rem;
            border-radius: 0.5rem;
            font-size: 0.42rem;
            text-align: center;
            background: #FA5564;
            color: #FFF;
            box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);

            &.disable {
                background: #acacac;
            }

            &.white {
                background: #FFFFFF;
                color: #9B9BA0;
            }
        }
    }
}

.confirm-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .confirm-modal {
        width: 8.4rem;
        padding: 0.53rem 0.4rem;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.4rem;
        border-radius: 0.8rem;
        background: #FFF;
        color: #0F0F14;

        .confirm-modal-title {
            margin-bottom: 0.87rem;
            font-size: 0.48rem;
        }

        .confirm-modal-content {
            padding: 0 0.4rem;
            margin-bottom: 0.87rem;
            color: #505062;

            .confirm-modal-content-qrcode {
                font-size: 0.35rem;

                p {
                    margin-top: 0.3rem;

                    em {
                        font-style: normal;
                        color: #FA5564;
                    }
                }

                .qrcode-title {
                    text-align: center;
                    font-size: 0.4rem;
                }

                .qrcode-img {
                    width: 4rem;
                    height: 4rem;
                    margin: 0.3rem auto;
                    text-align: center;
                    background: #F5F5FA;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .confirm-modal-button {
            display: flex;

            div {
                width: 3.6rem;
                height: 1.06rem;
                line-height: 1.06rem;
                margin: 0 0.15rem;
                font-size: 0.45rem;
                text-align: center;
                border-radius: 0.53rem;
            }

            .confirm-modal-button1 {
                border: 2px solid #D7D7DC;
                color: #9B9BA0;
            }

            .confirm-modal-button2 {
                background: #64814D;
                color: #FFFF;
            }
        }
    }
}