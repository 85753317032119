.membership-card {
  width: 100%;
  height: 100%;
  background: #f5f5fa;
  display: flex;
  flex-direction: column;
  header {
    width: 100%;
    background: url(./img/header-bg.png) no-repeat center center;
    background-size: cover;
    padding: 35px 0 247px 0;
    // height: 300px;
    box-sizing: border-box;
    .head {
      width: 100%;
      height: 40px;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .list {
    width: 100%;
    flex: 1;
    height: calc(100% - 400px);
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #0f0f14;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
    }
    // .nav {
    //   width: 100%;
    //   height: 120px;
    //   padding: 0 30px;
    //   box-sizing: border-box;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   margin-top: 20px;
    //   li {
    //     height: 70px;
    //     background: #ffffff;
    //     border: 2px solid #ff9664;
    //     border-radius: 35px;
    //     padding: 0 80px;
    //     line-height: 70px;
    //     font-size: 30px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #ff9664;
    //   }
    //   .active {
    //     background: #fa5564;
    //     border: none;
    //     font-size: 30px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #ffffff;
    //     position: relative;
    //   }
    //   .active:before {
    //     position: absolute;
    //     display: block;
    //     content: "";
    //     left: 50%;
    //     margin-left: -15px;
    //     // background: url(./img/active.png) no-repeat center center;
    //     width: 31px;
    //     height: 8px;
    //     bottom: -8px;
    //   }
    // }
    .card-box {
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
      flex: 1;
      overflow: hidden;
      overflow-y: scroll;
      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 230px;
          border-radius: 30px;
          overflow: hidden;
          width: 100%;
          margin-bottom: 40px;
          .left {
            height: 100%;
            background: #ffd2aa;
            width: 230px;
            display: flex;
            flex-direction: column;
            padding: 25px 0 30px;
            box-sizing: border-box;
            align-items: center;
            img {
              width: 60px;
              height: 60px;
              display: block;
            }
            p {
              font-size: 36px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #b4503c;
              margin-top: 9px;
            }
            span {
              background: #ffffff;
              opacity: 0.5;
              border-radius: 20px;
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #c87864;
              padding: 0px 24px;
              box-sizing: border-box;
              margin-top: 30px;
            }
          }
          .right {
            flex: 1;
            height: 100%;
            background-color: #fff;
            padding: 19px 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
              font-size: 32px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #8c645a;
            }
            .right-b {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .price {
                display: flex;
                flex-direction: column;
                span {
                  font-size: 26px;
                  font-family: PingFang SC;
                  font-weight: 300;
                  color: #0f0f14;
                  line-height: 36px;
                  em {
                    color: #fa5564;
                    font-style: normal;
                    margin-left: 10px;
                  }
                }
              }
              .btn {
                background: #fa5564;
                border-radius: 30px;
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #fff0aa;
                line-height: 35px;
                padding: 15px 40px;
                box-sizing: border-box;
              }
            }
          }
          //   border-bottom: 1px solid #f5f5fa;
          //   .points-left {
          //     height: 100%;
          //     display: flex;
          //     align-items: center;
          //     .icon-points {
          //       width: 36px;
          //       height: 36px;
          //       margin-right: 12px;
          //     }
          //     .title {
          //       font-size: 30px;
          //       font-family: PingFang SC;
          //       font-weight: 300;
          //       color: #0f0f14;
          //     }
          //     .time {
          //       font-size: 24px;
          //       font-family: PingFang SC;
          //       font-weight: 300;
          //       color: #b9b9be;
          //       margin-top: 11px;
          //       font-style: normal;
          //     }
          //   }
          //   .income {
          //     font-size: 36px;
          //     font-family: PingFang SC;
          //     font-weight: 600;
          //     color: #fa5564;
          //   }
          //   .expend {
          //     font-size: 36px;
          //     font-family: PingFang SC;
          //     font-weight: 400;
          //     color: #b9b9be;
          //   }
        }
        .loading {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #b9b9be;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .membership-card {
    .right {
      padding: 20px !important;
    }
    .btn {
      border-radius: 18px !important;
      font-size: 28px !important;

      padding: 15px 20px !important;
    }
    .left {
      span {
        padding: 10px !important;
      }
    }
  }
}
