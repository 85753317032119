*{
  padding: 0;
  margin: 0px;
  list-style: none;
  box-sizing: border-box;
}
html,body{
  width: 100%;
  height: 100%;
}
#root,.App{
  width: 100%;  height: 100%;
}
.adm-toast-main {

  padding:20px !important;
  max-width:60%!important;
}
.adm-auto-center-content{

font-size: 24px!important;
}