.shopBuyContainer {
  width: 100%;
  min-height: 100%;
  background: #f5f5fa;
}

.buyDetail {
  width: 100%;
  overflow-x: hidden;
  //   padding-top: 20px;
  box-sizing: border-box;
  padding-top: 110px;
  background: #f5f5fa;
  // min-height: 100%;

  header {
    width: 100%;
    height: 110px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .adm-image {
      img {
        width: 40px;
        height: 40px;
      }
    }
    span {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f0f14;
    }
  }
  .verified-info {
    width: 100%;
    background-color: #fff;
    padding: 60px 30px 40px 30px;
    box-sizing: border-box;
    margin-top: 10px;
    position: relative;
    h1 {
      width: 100%;
      display: flex;
      align-items: center;
      .adm-image {
        width: 43px;
        height: 30px;
      }
      span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        margin-left: 10px;
      }
    }
    .cont {
      width: 100%;
      display: flex;
      margin-top: 40px;

      .adm-image {
        width: 170px;
        height: 160px;
      }
      .info {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        line-height: 50px;
        margin-left: 33px;
      }
    }
    .crose {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .borrow-info {
    width: 100%;
    background-color: #fff;
    padding: 60px 30px 40px 30px;
    box-sizing: border-box;
    margin-top: 10px;
    h1 {
      width: 100%;
      display: flex;
      align-items: center;
      .adm-image {
        width: 43px;
        height: 30px;
      }
      span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        margin-left: 10px;
      }
    }
    .cont {
      width: 100%;
      margin-top: 40px;
      .avatar {
        width: 100%;
        display: flex;
        align-items: center;
        .adm-image {
          width: 70px;
          height: 70px;
          margin-right: 20px;
        }
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        span {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #a18873;
          line-height: 50px;
          padding: 0 11px;
          box-sizing: border-box;
          border: 1px solid #ac9685;
          border-radius: 8px;
          margin-left: 20px;
        }
      }
      .card-info {
        width: 100%;
        margin-top: 29px;
        p {
          width: 100%;
          display: flex;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          line-height: 50px;
          display: flex;
          align-items: center;
          span {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #a18873;
            line-height: 50px;
            padding: 0 11px;
            box-sizing: border-box;
            border: 1px solid #ac9685;
            border-radius: 8px;
            margin-left: 20px;
          }
          .red {
            color: #fa5564;
            font-style: normal;
          }
        }
      }
      .list {
        width: 100%;
        display: flex;

        flex-wrap: wrap;
        border-top: 2px solid #f5f5fa;
        padding-top: 30px;
        margin-top: 30px;
        li {
          //   display: flex;
          width: 50%;

          p {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            line-height: 50px;
            span {
              color: #0f0f14;
            }
            .red {
              color: #fa5564;
            }
          }
          //   flex-direction: column;

          //   justify-content: center;
          //   align-items: center;
          //   p {
          //     font-size: 28px;
          //     font-family: PingFang SC;
          //     font-weight: 300;
          //     color: #0f0f14;
          //     line-height: 50px;
          //     span {
          //       font-size: 40px;
          //       font-family: PingFang SC;
          //       font-weight: 400;
          //       color: #0f0f14;
          //       line-height: 50px;
          //       margin-right: 8px;
          //     }
          //     .red {
          //       color: #fa5564;
          //     }
          //   }
          //   em {
          //     font-size: 28px;
          //     font-family: PingFang SC;
          //     font-weight: 300;
          //     color: #a18873;
          //     line-height: 50px;
          //     font-style: normal;
          //   }
        }
      }
    }
  }
  .book-info {
    width: 100%;
    background-color: #fff;
    padding: 60px 30px 40px 30px;
    box-sizing: border-box;
    margin-top: 10px;
    > h1 {
      width: 100%;
      display: flex;
      align-items: center;
      .adm-image {
        width: 43px;
        height: 30px;
      }
      span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        margin-left: 10px;
      }
    }
    .cont {
      width: 100%;

      margin-top: 40px;
      .item:not(:last-child) {
        margin-bottom: 60px;
      }
      .item {
        display: flex;
        width: 100%;
        overflow: hidden;

        .pic {
          width: 238px;
          height: 260px;
          flex-shrink: 0;
          margin-right: 40px;
          .adm-image {
            width: 100%;
            height: 100%;
          }
        }
        .book {
          flex: 1;
          .title {
            width: 400px;
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0f0f14;
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .price {
            display: flex;
            margin-top: 30px;
            align-items: flex-end;
            span:first-child {
              font-size: 40px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #fa5564;
              line-height: 40px;
              em {
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 600;
                color: #fa5564;
                line-height: 40px;
                margin-right: 9px;
                font-style: normal;
              }
            }
            span:last-child {
              margin-left: 19px;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 300;
              text-decoration: line-through;
              color: #737378;
            }
          }
          .book-infos {
            display: flex;
            .detail {
              width: 100%;
              margin-top: 23px;
              overflow: hidden;
              p {
                width: 100%;
                padding-right: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #737378;
                line-height: 34px;
                margin-bottom: 20px;
                .adm-image {
                  width: 14px;
                  height: 26px;
                }
                span {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
            }
            .todetail {
              padding: 10px;
              box-sizing: border-box;
              margin-top: 20px;
              .adm-image-img {
                width: 14px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }
  .book-pics {
    width: 100%;
    background-color: #fff;
    padding: 60px 30px 40px 30px;
    box-sizing: border-box;
    margin-top: 10px;
    h1 {
      width: 100%;
      display: flex;
      align-items: center;
      .adm-image {
        width: 43px;
        height: 30px;
      }
      span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0f0f14;
        margin-left: 10px;
      }
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 40px 0 70px 0;
      li {
        width: 215px;
        height: 310px;
        border-radius: 10px;
        background: #f5f5fa;
        border-radius: 10px;
      }
    }
  }
  .btn {
    width: 100%;
    height: 168px;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-top: 18px;
    background-color: #fff;
    margin-top: 12px;

    span {
      width: 100%;
      background: #fa5564;
      border-radius: 40px;
      height: 80px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 80px;
    }
    .unverifiedBtn {
      width: 100%;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .cancel {
        margin-right: 30px;
        border: 2px solid #d7d7dc;
        border-radius: 40px;
        background-color: #fff;
        border: 2px solid #d7d7dc;
        border-radius: 40px;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737378;
      }
      span {
        flex: 1;
      }
    }
  }
  .fixed {
    position: fixed;
    bottom: 0;
  }
}
