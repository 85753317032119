.product-detail {
  width: 100%;
  // position: relative;
  overflow-x: hidden;
  background: #f5f5fa;
  padding-bottom: 128px;
  box-sizing: border-box;
  header {
    width: 100%;
    height: 750px;
    position: relative;
    display: flex;
    align-items: center;

    // justify-content: center;
    .head-photo {
      width: 100%;
      height: 100%;
      background: no-repeat 100% 100%;
    }
    // .adm-swiper {
    //   width: 100%;
    //   height: 100%;
    //   .adm-swiper-slide {
    //     width: 100%;
    //     height: 100%;
    //     .swiper-content {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    //   .adm-swiper-indicator {
    //     bottom: 20px;
    //     .adm-page-indicator-dot {
    //       width: 20px;
    //       height: 20px;
    //       border: 2px solid #f5f5f5;
    //       border-radius: 50%;
    //       background: transparent;
    //       margin-right: 12px;
    //     }
    //     .adm-page-indicator-dot-active {
    //       background: #fff;
    //     }
    //   }
    // }
    // .cover {
    //   width: 100%;
    //   height: 198px;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   background: url(./img/swiper-cover.png) no-repeat center center;
    //   background-size: cover;
    //   img {
    //     width: 22px;
    //   }
    // }
    .goback {
      position: absolute;
      height: 40px;
      left: 0;
      top: 40px;
      padding: 0 40px;
      box-sizing: border-box;
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .product-des {
    width: 100%;
    background: #fff;
    padding: 0 30px 70px;
    box-sizing: border-box;
    overflow: hidden;

    .des {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0;
      .price {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .current-price {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fa5564;
          line-height: 40px;
          span {
            em {
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #fa5564;
              line-height: 40px;
              font-style: normal;
            }
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #fa5564;
            line-height: 40px;
            b {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #9b9ba0;
              margin-left: 10px;
            }
          }
          // display: flex;
        }
        .original-price {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          text-decoration: line-through;
          color: #9b9ba0;
          margin-top: 15px;
        }
      }
      .endtime {
        display: flex;
        align-items: center;
        .adm-image {
          width: 32px;
          height: 34px;
          margin-right: 20px;
          display: block;
        }
        .time {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #9b9ba0;
          }
          p {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #fa5564;
            line-height: 40px;
            margin-top: 10px;
          }
        }
      }
      .notLog {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #a18774;
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 26px;
          margin-left: 10px;
        }
      }
    }
    .discount {
      width: 100%;
      height: 60px;
      background: #fff09b;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      // justify-content: space-between;
      padding: 0 17px 0 5px;
      box-sizing: border-box;
      .vip {
        width: 50px;
        height: 50px;
        display: block;
        margin-right: 10px;
      }
      p {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        display: flex;
        flex: 1;
        span {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #fa5564;
          margin-left: 11px;
        }
      }
      .discount-right {
        width: 12px;
        height: 24px;
        display: block;
      }
    }
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .name {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
        line-height: 40px;
      }
      .collect {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 90px;
        flex-shrink: 0;
        .adm-image {
          width: 46px;
          height: 44px;
        }
        span {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #9b9ba0;
          margin-top: 10px;
        }
      }
    }
    .product-bottom {
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          line-height: 60px;
          width: calc(100% - 160px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        > div {
          display: flex;
          align-items: center;
          width: 160px;

          span {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
          }
          .arrow-right {
            width: 14px;
            height: 26px;
            margin-left: 18px;
          }
        }
      }
    }
    .gift-pro {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 50%;
        display: flex;
        span {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #737378;
          line-height: 60px;
        }
        p {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #0f0f14;
          line-height: 60px;
        }
      }
    }
  }
  .stores-distribution {
    margin-top: 10px;
    background-color: #fff;
    padding: 25px 30px 70px;
    box-sizing: border-box;
    .title {
      width: 100%;
      background: url(../storeDetail/img/douhao.png) no-repeat left center;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0f0f14;
      height: 80px;
      padding-left: 30px;
      padding-top: 10px;
      box-sizing: border-box;
      background-size: 62px 80px;
    }
    .address {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      span {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #737378;
        line-height: 50px;
        margin-right: 30px;
      }
      > div {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          flex: 1;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 50px;
        }
        .arrow-right {
          width: 14px;
          height: 26px;
          margin-left: 50px;
        }
      }
    }
    .freight {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        line-height: 50px;
        margin-right: 30px;
      }
      p {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fa5564;
        line-height: 50px;
        flex: 1;
      }
    }
    .shop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 39px;
      .label {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        line-height: 50px;
        margin-right: 30px;
      }
      .shop-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        .name-title {
          display: flex;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 50px;
          align-items: center;
          .change {
            width: 130px;
            height: 50px;
            margin-left: 20px;
          }
        }
        .evaluate {
          display: flex;
          align-items: center;
          margin-top: 13px;
          background: #fff;
          .stars {
            img {
              width: 36px;
              height: 34px;
              margin-right: 6px;
            }
          }
          span {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #a18774;
            margin-left: 21px;
          }
        }
      }
      .shop-icon {
        display: flex;
        > div {
          margin-left: 45px;
          display: flex;
          flex-direction: column;
          img {
            width: 56px;
            height: 52px;
          }
          span {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #b9b9be;
            display: block;
            margin-top: 13px;
          }
        }
      }
    }
    .position {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 30px;
      span {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #0f0f14;
        line-height: 50px;
        margin-right: 30px;
      }
      p {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
        line-height: 40px;
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        em {
          font-style: normal;
          font-weight: bold;
          margin-right: 8px;
        }
      }
    }
    .postcard {
      width: 100%;
      // height: 260px;
      background: rgba(100, 129, 77, 0.05);
      // opacity: 0.05;
      border-radius: 20px;
      margin-top: 30px;
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      .book_img {
        width: 200px;
        height: 200px;
        background: #bebebe;
        border-radius: 6px;
        margin-right: 30px;
        overflow: hidden;
        flex-shrink: 0;
        display: block;
        img {
          width: 200px;
          height: 200px;
        }
      }
      .val {
        width: 400px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        padding: 30px 0;
        box-sizing: border-box;
        justify-content: space-between;
        .book_title {
          width: 100%;
          font-size: 28px;
          color: #0f0f14;
          line-height: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .name {
          width: 100%;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 40px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          margin-top: 12px;
          span {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #737378;
            line-height: 40px;
          }
          div {
            span {
              display: block;
              margin-bottom: 5px;
            }
          }
        }
        .none-gift {
          width: 100%;

          .warning {
            width: 100%;
            display: flex;
            align-items: center;

            img {
              width: 36px;
              height: 36px;
              margin-right: 14px;
            }
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fa5564;
            line-height: 40px;
          }
          .change-shop {
            width: 100%;
            margin-top: 12px;
            img {
              width: 180px;
              height: 50px;
            }
          }
        }
      }
    }
  }
  .ceiling {
    width: 100%;
    height: 110px;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    padding-left: 40px;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      display: block;
    }
    ul {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #737378;
          text-decoration: none;
        }
      }
      .active {
        border-bottom: solid 4px #ffd345;
        a {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #64814d;
        }
      }
    }
  }
  .ceil-hidden {
    display: none;
  }
  .comment-box {
    margin-top: 10px;
    background-color: #fff;
    padding: 25px 30px 70px;
    box-sizing: border-box;
    .title {
      width: 100%;
      background: url(../storeDetail/img/douhao.png) no-repeat left center;
      height: 80px;
      padding-left: 30px;
      background-size: 62px 80px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0f0f14;
        em {
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #737378;
          font-style: normal;
          margin-left: 19px;
        }
      }
      .content-detail {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #737378;
        font-style: normal;
        img {
          width: 14px;
          height: 26px;
          margin-left: 17px;
        }
      }
    }
    .comments-content {
      width: 100%;
      padding: 0 30px;
      margin-top: 35px;
      > div {
        margin-bottom: 50px;
        .head {
          width: 100%;
          display: flex;
          align-items: center;
          .head-photo {
            width: 54px;
            height: 54px;
            border-radius: 100%;
            display: block;
            margin-right: 16px;
          }
          .nickname {
            padding: 1px 0;
            box-sizing: border-box;
            span {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #737378;
            }
            .stars {
              margin-top: 7px;
              img {
                width: 21px;
                height: 20px;
                margin-right: 4px;
              }
            }
          }
        }
        .content {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0f0f14;
          line-height: 40px;
          margin-top: 26px;
        }
        .images {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;
          .head-photo {
            width: 300px;
            height: 300px;
            border-radius: 10px;
            display: block;
          }
          div {
            width: 300px;
            height: 300px;
            border-radius: 10px;

            // background-color: #737378;
          }
        }
        .many {
          width: 100%;
          display: flex;

          flex-wrap: wrap;
          margin-top: 30px;
          .head-photo {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            display: block;
            margin-bottom: 20px;
            // margin-bottom: ;
          }
          .head-photo:not(:nth-child(3n)) {
            margin-right: 15px;
          }
          div {
            width: 200px;
            height: 200px;
            border-radius: 10px;
          }
        }

        p {
          margin-top: 19px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #64646f;
        }
      }
      .more {
        width: 100%;
        margin-top: 70px;
        span {
          width: 270px;
          height: 80px;
          border: 2px solid #d7d7dc;
          border-radius: 40px;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #737378;
          cursor: pointer;
          // display: block;
          // text-align: center;
          // line-height: 80px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 14px;
            height: 26px;
            display: block;
            margin-left: 20px;
          }
        }
      }
    }
  }
  .goods-detail {
    margin-top: 10px;
    background-color: #fff;
    padding: 25px 30px 70px;
    box-sizing: border-box;
    .title {
      width: 100%;
      background: url(../storeDetail/img/douhao.png) no-repeat left center;
      height: 80px;
      padding-left: 30px;
      background-size: 62px 80px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0f0f14;
    }
    h1 {
      height: 60px;
      margin-bottom: 40px;
      span {
        // display: block;
        background: url(./img/title-bg.png) no-repeat;
        background-size: 100% 60px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;

        padding: 5px 34px 23px;
        box-sizing: border-box;
      }
    }
    p {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #737378;
      line-height: 46px;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 100px;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  .shop-cart {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    // height: 100px;
    display: flex;
    padding: 16px 30px;
    box-sizing: border-box;
    background: #fff;
    z-index: 999;
    justify-content: space-between;
    // padding-top: 15px;
    .cart-left {
      display: flex;

      .connect {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 30px;
        img {
          width: 56px;
          height: 52px;
        }
        span {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #9b9ba0;
          margin-top: 11px;
        }
      }
      .mall {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        position: relative;
        img {
          width: 60px;
          height: 50px;
        }
        span {
          margin-top: 11px;
          color: #9b9ba0;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
        }
        em {
          position: absolute;
          right: -10px;
          top: -10px;
          width: 40px;
          height: 40px;
          background: #fa5564;
          border-radius: 50%;
          font-style: normal;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
        }
      }
    }
    .card-right {
      display: flex;
      .addCart {
        background: url(./img/addCart.png) no-repeat center center;
        background-size: 100% 100%;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 214px;
        height: 84px;
        line-height: 70px;
        text-align: center;
      }
      .buy {
        background: url(./img/buy.png) no-repeat center center;
        background-size: 100% 100%;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 214px;
        height: 84px;
        line-height: 70px;
        text-align: center;
        margin-left: 6px;
      }
      .stockout {
        width: 420px;
        height: 70px;
        background: #d7d7dc;
        border-radius: 35px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 70px;
        text-align: center;
      }
    }
  }
}
html {
  scroll-padding-top: 120px;
  box-sizing: border-box;
}
